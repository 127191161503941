export const createAgenda = `
    mutation createVotecastAgenda($input: CreateVotecastAgendaInput!) {
        createVotecastAgenda(input: $input) {
            uid
            customer_uid
            meeting_status
        }
    }
`;

export const createVotecastMember = `
    mutation createVotecastMember($input: CreateVotecastMemberInput!) {
        createVotecastMember(input: $input) {
            uid
            customer_uid
            display_name
            status
            availability_status
            voting
            chair
            person_uid
            agenda_uid
        }
    }
`;

export const createMembers = `
    mutation createVotecastMembers($input: [CreateVotecastMemberInput]!) {
        createVotecastMembers(input: $input) {
            uid
            customer_uid
            display_name
            status
            availability_status
            voting
            chair
            person_uid
        }
    }
`;

export const createCustomer = `
    mutation createVotecastCustomer($input: CreateVotecastCustomerInput!) {
        createVotecastCustomer(input: $input) {
            uid
            domain
        }
    }
`;

export const createVotecastAgendaItem = `
    mutation createVotecastAgendaItem($input: CreateVotecastAgendaItemInput!) {
        createVotecastAgendaItem(input: $input) {
            uid
            customer_uid
            agenda_uid
            status
            parent_uid
            bulk_action
            type
            metadata
        }
    }
`;

export const createVotecastAgendaItems = `
    mutation createVotecastAgendaItems($input: [CreateVotecastAgendaItemInput]!) {
        createVotecastAgendaItems(input: $input) {
            uid
            customer_uid
            agenda_uid
            status
            parent_uid
        }
    }
`;

export const createClip = `
    mutation createVotecastClip($input: CreateVotecastClipInput!) {
        createVotecastClip(input: $input) {
            uid
            customer_uid
            agenda_uid
            broadcast_id
            action
            remaining_time
            stream_url
        }
    }
`;

export const createMotionActions = `
    mutation createVotecastMotionActions($input: [CreateVotecastMotionActionInput]!) {
        createVotecastMotionActions(input: $input) {
            uid
            customer_uid
            name
            description
            position           
        }
    }
`;

export const createMotionPassageRule = `
    mutation createVotecastMotionPassageRule($input: CreateVotecastMotionPassageRuleInput!) {
        createVotecastMotionPassageRule(input: $input) {
            uid
            customer_uid
            rule
        }
    }
`;

export const createMotionResults = `
    mutation createVotecastMotionResults($input: [CreateVotecastMotionResultInput]!) {
        createVotecastMotionResults(input: $input) {
            uid
            customer_uid
            name
            description
            position
        }
    }
`;

export const createMotionTypes = `
    mutation createVotecastMotionTypes($input: [CreateVotecastMotionTypeInput]!) {
        createVotecastMotionTypes(input: $input) {
            uid
            customer_uid
            name
            description
            position
        }
    }
`;

export const createVotecastSpeaker = `
    mutation createVotecastSpeaker($input: CreateOrUpdateVotecastSpeakerInput!) {
        createVotecastSpeaker(input: $input) {
            uid
            customer_uid
            agenda_uid
            name
            speaker_type
            duration
            position
            specialPosition
            item_uid
            request_origin            
            start_time
            status
        }
    }
`;

export const createVotecastSpeakers = `
    mutation createVotecastSpeakers($input: [CreateOrUpdateVotecastSpeakerInput]!) {
        createVotecastSpeakers(input: $input) {
            uid
            customer_uid
            agenda_uid
            name
            speaker_type
            duration
            position
            specialPosition
            item_uid
            request_origin
            start_time
            status
        }
    }
`;

export const createVotingConfigurations = `
    mutation createVotecastVotingConfigurations($input: [CreateVotecastVotingConfigurationInput]!) {
        createVotecastVotingConfigurations(input: $input) {
            uid
            customer_uid
            name
            description
            configuration_type
            ballot
        }
    }
`;

export const createVotecastBallots = `
    mutation createVotecastBallots($input: [CreateOrUpdateBallotInput]!) {
        createVotecastBallots(input: $input) {
            uid
            customer_uid
            agenda_uid
            motion_uid
            parent_agenda_item_uid
            member_uid
            member_name
            voting_configuration_name
            request_origin
        }
    }
`;

export const createVotecastMotion = `
    mutation createVotecastMotion($input: CreateOrUpdateMotionInput) {
        createVotecastMotion(input: $input) {
            uid
            customer_uid
            agenda_uid
            position
            motion_action_name
            motioner_uid
            motioner_name
            seconder_uid
            seconder_name
            motion_result_name
            parent_agenda_item_uid
            request_origin
            voting_status
        }
    }
`;

export const updateAgendaMeetingStatus = `
    mutation updateVotecastAgendaMeetingStatus($input: UpdateVotecastAgendaMeetingStatusInput!) {
        updateVotecastAgendaMeetingStatus(input: $input) {
            uid
            customer_uid
            meeting_status
            activeclip_uid
            meeting_status_timestamp
        }
    }
`;

export const updateMemberAvailabilityStatus = `
    mutation updateVotecastMemberAvailabilityStatus($input: UpdateVotecastMemberAvailabilityStatusInput) {
        updateVotecastMemberAvailabilityStatus(input: $input) {
            uid
            customer_uid
            display_name
            status
            availability_status
            chair
            voting
        }
    }
`;

export const updateVotecastMember = `
    mutation updateVotecastMember($input: UpdateVotecastMemberInput) {
        updateVotecastMember(input: $input) {
            uid          
            display_name
            status
            chair
            voting
            agenda_uid
            customer_uid
        }
    }
`;

export const updateAgendaItemStatus = `
    mutation updateVotecastAgendaItemStatus($input: UpdateVotecastAgendaItemStatusInput) {
        updateVotecastAgendaItemStatus(input: $input) {
            customer_uid
            uid
            agenda_uid
            status
            title
            type
            mema_metadata_id
            entrytime
            metadata
        }
    }
`;

export const updateAgendaItem = `
    mutation updateVotecastAgendaItem($input: UpdateVotecastAgendaItemInput) {
        updateVotecastAgendaItem(input: $input) {
            customer_uid
            uid
            agenda_uid
            status
            title
            type            
            position
            metadata
        }
    }
`;

export const updateVotecastAgendaItemBulkAction = `
    mutation updateVotecastAgendaItemBulkAction($input: UpdateVotecastAgendaItemBulkActionInput) {
        updateVotecastAgendaItemBulkAction(input: $input) {
            customer_uid
            uid
            agenda_uid
            status
            title
            type            
            bulk_action
            metadata
        }
    }
`;

export const updateAgendaItemPosition = `
    mutation updateVotecastAgendaItemPosition($input: UpdateVotecastAgendaItemPositionInput) {
        updateVotecastAgendaItemPosition(input: $input) {
            customer_uid
            uid
            agenda_uid
            position
            title
            type
            mema_metadata_id
        }
    }
`;

export const updateVotecastMotion = `
    mutation updateVotecastMotion($input: CreateOrUpdateMotionInput) {
        updateVotecastMotion(input: $input) {
            uid
            customer_uid
            agenda_uid
            motion_action_name
            motion_result_name
            motioner_uid
            motioner_name
            seconder_uid
            seconder_name
            request_origin
            entrytime
            voting_status
            parent_agenda_item_uid
            text
        }
    }
`;

export const updateVotecastBallot = `
    mutation updateVotecastBallot($input: CreateOrUpdateBallotInput) {
        updateVotecastBallot(input: $input) {
            uid
            customer_uid
            agenda_uid
            motion_uid
            parent_agenda_item_uid
            member_uid
            member_name
            voting_configuration_name
            request_origin
        }
    }
`;

export const updateVotecastSpeaker = `
    mutation updateVotecastSpeaker($input: CreateOrUpdateVotecastSpeakerInput) {
        updateVotecastSpeaker(input: $input) {
            uid
            customer_uid
            agenda_uid
            name
            speaker_type
            duration
            position
            specialPosition
            item_uid
            start_time
            status
            request_origin
        }
    }
`;

export const deleteMembers = `
    mutation deleteVotecastMembers($input: [DeleteVotecastMemberInput]!) {
        deleteVotecastMembers(input: $input) {
            deletedItems {
                uid
            }
            unprocessedKeys {
                uid
            }
        }
    }
`;

export const deleteVotecastAgendaItem = `
    mutation deleteVotecastAgendaItem($agenda_uid: String!, $uid: ID!, $type: String) {
        deleteVotecastAgendaItem(agenda_uid: $agenda_uid, uid: $uid, type: $type) {
            uid
            agenda_uid
            type
            metadata
        }
    }
`;


export const deleteVotecastAgenda = `
    mutation deleteVotecastAgenda($customer_uid: String!, $uid: ID!) {
        deleteVotecastAgenda(customer_uid: $customer_uid, uid: $uid) {
            uid
        }
    }
`;

export const deleteVotecastClip = `
    mutation deleteVotecastClip($agenda_uid: String!, $uid: ID!) {
        deleteVotecastClip(agenda_uid: $agenda_uid, uid: $uid) {
            uid
        }
    }
`;

export const deleteVotecastClips = `
    mutation deleteVotecastClips($input: [DeleteVotecastClipInput]!) {
        deleteVotecastClips(input: $input) {
            deletedItems {
                uid
            }
            unprocessedKeys {
                uid
            }
        }
    }
`;

export const deleteVotecastAgendaItems = `
    mutation deleteVotecastAgendaItems($input: [DeleteVotecastAgendaItemInput]!) {
        deleteVotecastAgendaItems(input: $input) {
            deletedItems {
                uid
            }
            unprocessedKeys {
                uid
            }
        }
    }
`;

export const deleteVotecastMotions = `
    mutation deleteVotecastMotions($input: [DeleteVotecastMotionInput]!) {
        deleteVotecastMotions(input: $input) {
            deletedItems {
                uid
            }
            unprocessedKeys {
                uid
            }
        }
    }
`;

export const deleteVotecastSpeakers = `
    mutation deleteVotecastSpeakers($input: [DeleteVotecastSpeakerInput]!) {
        deleteVotecastSpeakers(input: $input) {
            deletedItems {
                uid
            }
            unprocessedKeys {
                uid
            }
        }
    }
`;

export const deleteVotecastMotion = `
    mutation deleteVotecastMotion($agenda_uid: String!, $uid: ID!, $request_origin: String) {
        deleteVotecastMotion(agenda_uid: $agenda_uid, uid: $uid, , request_origin: $request_origin) {
            uid
            agenda_uid
            customer_uid
            motion_action_name
            motioner_uid
            motioner_name
            seconder_uid
            seconder_name
            request_origin
        }
    }
`;

export const deleteVotecastBallots = `
    mutation deleteVotecastBallots($input: [DeleteVotecastBallotInput]!) {
        deleteVotecastBallots(input: $input) {
            deletedItems {
                uid
            }
            unprocessedKeys {
                uid
            }
        }
    }
`;

export const deleteVotecastSpeaker = `
    mutation deleteVotecastSpeaker($item_uid: String!, $uid: ID!, $request_origin: String) {
        deleteVotecastSpeaker(item_uid: $item_uid, uid: $uid, request_origin: $request_origin) {
            customer_uid
            agenda_uid
            uid
            item_uid
            duration
            name
            position
            speaker_type
            request_origin
        }
    }
`;

export const deleteVotecastMember = `
    mutation deleteVotecastMember($uid: ID!, $customer_uid: String! ) {
        deleteVotecastMember(uid: $uid, customer_uid: $customer_uid ) {
            uid
            customer_uid
            agenda_uid
        }
    }
`;