import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLiveMeeting,
  setCurrentItem,
} from '../../../common/liveMeetingSlice';
import {
  setCalledSpeaker,
  setSpeakers,
  deleteSpeaker
} from '../../../common/liveMeetingSpeakerSlice';
import { selectIsCloudVotingEnabled } from '../../../common/settingSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@trussworks/react-uswds';
import { selectSelectedItemKey } from '../../ActiveAgenda/activeAgendaSlice';
import CalledSpeaker from './CalledSpeaker';
import { v4 as uuidv4 } from "uuid";
import { createVotecastSpeaker, deleteVotecastSpeaker } from '../../../../../amplify/graphql/mutations';
import { SpeakerType, VotecastRequestOrigin, VotecastAudience } from '../../../../constants/cloudVotingConstants';
import { invokeGraphqlOperation } from '../../../../helpers/votecastWebHelpers/graphqlOperations';
import { speakerCustomSort } from '../../../../helpers/votecastWebHelpers/speakerCustomSort';

export default function SpeakerContainerInDetail() {
  const dispatch = useDispatch();

  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);
  const liveMeeting = useSelector(selectLiveMeeting);
  const votecastAgendaUid = liveMeeting.votecastAgendaUid;
  const customerId = useSelector(state => state.auth.customer_uid);

  const liveCurrentItemGuid = useSelector(state => state.liveMeeting.currentItemGuid);
  const meetingGuid = useSelector((state) => state.liveMeeting.meetingGuid);
  const currentActiveAgendaId = useSelector(
    (state) => state.activeAgenda?.agenda?.uid
  );
  const selectedItemKey = useSelector(selectSelectedItemKey);
  const itemsDictionary = useSelector(state => state.liveMeeting.agendaItemsDictionary);
  const speakers = useSelector((state) => state.liveMeetingSpeaker.speakers);
  const currentUser = useSelector((state) => state.auth.username);
  const calledSpeaker = useSelector((state) => state.liveMeetingSpeaker.calledSpeaker);
  const calledSpeakerUid = calledSpeaker ? calledSpeaker.uid : '';
  const isChair = useSelector(
    (state) => state.liveMeeting.memberDetails?.isChair
  );
  const isAttendeePresent = useSelector(
    (state) => state.liveMeeting.memberDetails?.isAttendeePresent
  );
  const isMemberJoined = useSelector(
    (state) => state.liveMeeting.memberDetails?.memberJoinStatus
  );

  if (!isCloudVotingEnabled) {
    return null;
  }

  const shouldShowSpeaker =
    isCloudVotingEnabled && meetingGuid === currentActiveAgendaId;

  //return null if not shouldShowSpeaker
  if (!shouldShowSpeaker) {
    return null;
  }
  const selectedItemHypatia = itemsDictionary[selectedItemKey];
  const shouldShowCalledSpeaker = selectedItemKey === liveCurrentItemGuid;

  //check if name exist in speakers list
  const isRequestedToSpeak = speakers.some(speaker =>
    speaker.name === currentUser && speaker.speaker_type === SpeakerType.ELECTED_OFFICIAL && speaker.item_uid === selectedItemHypatia?.uid && speaker.uid !== calledSpeakerUid
  );

  const authParameter = { customerId: customerId, audience: VotecastAudience.LEGISLATE };

  const requestToSpeak = async () => {
    const sortedSpeakers = speakerCustomSort(speakers.filter(sp => sp.item_uid === selectedItemHypatia?.uid && sp.speaker_type === SpeakerType.ELECTED_OFFICIAL));
    const position = sortedSpeakers.length > 0 ? sortedSpeakers[sortedSpeakers.length - 1].position : 1;
    const speakerRequest = {
      uid: uuidv4(),
      name: currentUser,
      speaker_type: SpeakerType.ELECTED_OFFICIAL,
      duration: -1, // Untimed
      position: position,
      item_uid: selectedItemHypatia?.uid
    };

    const speakerInput = {
      ...speakerRequest,
      customer_uid: customerId,
      agenda_uid: votecastAgendaUid,
      request_origin: VotecastRequestOrigin.ATTENDEE_CREATE
    };
    await invokeGraphqlOperation(createVotecastSpeaker, { input: speakerInput }, authParameter);
    dispatch(setSpeakers(speakerCustomSort([...speakers, speakerInput])));
  };

  const withdrawRTP = async () => {
    // remove user from speakers list
    const speakerToDelete = speakers.find(speaker => speaker.name === currentUser && speaker.item_uid === selectedItemHypatia.uid && speaker.speaker_type === SpeakerType.ELECTED_OFFICIAL);
    await invokeGraphqlOperation(deleteVotecastSpeaker,
      { item_uid: speakerToDelete.item_uid, uid: speakerToDelete.uid, request_origin: VotecastRequestOrigin.ATTENDEE_DELETE }, authParameter)
    const updatedSpeakers = speakers.filter(speaker => speaker.uid !== speakerToDelete.uid);
    dispatch(setSpeakers(updatedSpeakers));
  };
  const handleClearCurrentSpeaker = async () => {
    await invokeGraphqlOperation(deleteVotecastSpeaker,
      { item_uid: calledSpeaker.item_uid, uid: calledSpeaker.uid, request_origin: VotecastRequestOrigin.ATTENDEE_DELETE }, authParameter);
      dispatch(deleteSpeaker(calledSpeaker.uid));
      dispatch(setCalledSpeaker(undefined));
  };

  const isSpeaking = !!calledSpeaker?.name && selectedItemHypatia?.uid === calledSpeaker?.item_uid;
  const isCurrentUserSpeaker = calledSpeaker?.name === currentUser && calledSpeaker?.speaker_type === SpeakerType.ELECTED_OFFICIAL && selectedItemHypatia?.uid === calledSpeaker?.item_uid;

  const requestToSpeakButton = (
    <Button
      type="button"
      size="small"
      className="speaker-button padding-y-1"
      onClick={requestToSpeak}
      title="Click to request to speak"
      disabled={!isCloudVotingEnabled || !isAttendeePresent || isCurrentUserSpeaker || !isMemberJoined}
    >
      <FontAwesomeIcon icon={'microphone'} className="margin-right-1" />
      <span>Request to Speak</span>
    </Button>
  );

  const withdraw = (
    <div className="speaker-withdraw">
      <div className="flex-1">
        <div className="speaker-message">
          <FontAwesomeIcon
            icon={['far', 'check-circle']}
            color="#4CAF50"
            className="margin-right-1"
          />
          <span>You have requested to speak on this item</span>
        </div>
      </div>
      <div className="flex-auto">
        <Button
          type="button"
          size="small"
          className="withdraw-button"
          unstyled
          onClick={withdrawRTP}
          title="Click to withdraw speak request"
        >
          <FontAwesomeIcon
            icon={'times'}
            size={'lg'}
            className="margin-right-1"
          />
          <span>Withdraw Request</span>
        </Button>
      </div>
    </div>
  );

  const clearCurrentSpeakerButton = (
    <div className="flex-auto">
      <Button
        type="button"
        unstyled
        onClick={handleClearCurrentSpeaker}
        disabled={!isSpeaking}
        className="speaker-clear-button"
      >
        <span>Clear Current Speaker</span>
      </Button>
    </div>
  );

  return (
    <div className="speaker-container-indetail padding-0 margin-top-2 margin-bottom-2">
      <div>
        <div className="margin-bottom-2 display-flex">
          <span className="text-base-darkest text-bold font-sans-xs flex-1">
            Speaker
          </span>
          {isChair && shouldShowCalledSpeaker && clearCurrentSpeakerButton}
        </div>
        <div>{isRequestedToSpeak ? withdraw : requestToSpeakButton}</div>
      </div>
      <div>{shouldShowCalledSpeaker && <CalledSpeaker />}</div>
    </div>
  );
}