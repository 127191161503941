export const MeetingStatus = {
  OPEN: "open",
  RUNNING: "running",
  PAUSED: "paused",
  STOPPED: "stopped"
};

export const AvailabilityStatus = {
  NOT_JOINED: "not joined",
  JOINED: "joined",
  LEFT: "left"
};

export const AgendaItemActiveStatus = {
  ACTIVE: "Active",
  INACTIVE: "Inactive"
};

export const ConfigTypeEnum = {
  motioner: 0,
  seconder: 1,
  for: 2,
  against: 3,
  abstain: 4,
  absent: 5,
  custom: 6
};

export const ClipActionEnum = {
  Start: 0,
  Stop: 1,
  Pause: 2,
  Resume: 3,
  Extend: 4
};

export const AgendaItemType = {
  Agenda: "Agenda",
  Motion: "Motion",
  Vote: "Vote",
  RollCall: "rollcall"
};

export const VoteActionEnum = {
  Yes: "yes",
  No: "no",
  Abstain: "abstain",
};

export const MemberAttendance = {
  PRESENT: "present",
  ABSENT: "absent",
  EXCUSED: "excused"
};

export const SpeakerType = {
  PUBLIC: "Public",
  STAFF: "Staff",
  ELECTED_OFFICIAL: "Elected official"
}

export const SpeakerStatus = {
  CALLED: "called",
  STARTED: "started",
  STOPPED: "stopped",
  UNCALLED: "uncalled"
}

export const SpeakerTimeType = {
  FIXED: "Fixed",
  INCREMENTAL: "Incremental"
}

export const VotecastRequestOrigin = {
  CLERK: "Clerk",
  ATTENDEE: "Attendee",
  ATTENDEE_CHAIR: "Attendee_Chair",
  CLERK_CREATE: "Clerk_Create",
  CLERK_UPDATE: "Clerk_Update",
  CLERK_DELETE: "Clerk_Delete",
  ATTENDEE_CREATE: "Attendee_Create",
  ATTENDEE_UPDATE: "Attendee_Update",
  ATTENDEE_DELETE: "Attendee_Delete",
  ATTENDEE_CHAIR_UPDATE: "Attendee_Chair_Update",
  MUTATION_DELETE: "Delete"
};

export const VotecastVotingStatus = {
  STARTED: "Started",
  STOPPED: "Stopped",
  COMPLETED: "Completed"
}

export const VotecastBulkAction = {
  CLERK_VOTE_BULK_CREATE: "Clerk_Vote_Bulk_Create",
  CLERK_RESET_VOTES: "Clerk_Reset_Votes",
  CLERK_MARK_VOTES_ALL_YES: "Clerk_Mark_Votes_All_Yes",
  CLERK_SPEAKER_BULK_CREATE: "Clerk_Speaker_Bulk_Create",
  ATTENDEE_CHAIR_RESET_VOTES: "Attendee_Chair_Reset_Votes"
};

export const VotecastAudience = {
  LIMA_WEB: "limaweb",
  LEGISLATE: "legislate",
  VCD: "vcd"
};

export const VotingResult = {
  PASSED :"passed",
  FAILED : "failed"
};

export const JWTTokenExpiryTimeInSec = 300;