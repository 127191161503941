import { v4 as uuidv4 } from 'uuid';
import {
  markAllVotesYes,
  resetVotes,
  updateVotePassageRule,
  updateVoterBallot,
  updateVoteResult,
} from '../reducers/votes';

import { displayError } from '../reducers/error';

import {
  markAllVotesYesAPI,
  resetVotesAPI,
  setVotePassageRuleAPI,
  setVoteResultAPI,
  updateVoterBallotAPI
} from '../../../sources/hypatia/votesService';

import { invokeGraphqlOperation } from '../../../helpers/votecastWebHelpers/graphqlOperations';

import {
  createVotecastBallots,
  createMotionPassageRule,
  createMotionResults,
  updateVotecastMotion,
  updateVotecastBallot,
  updateVotecastAgendaItemBulkAction
} from '../../../../amplify/graphql/mutations';

import { processChunks } from '../../../helpers/votecastWebHelpers/openCloseMeetingActions';
import { MeetingStatus, VotecastRequestOrigin, VotecastBulkAction, VotecastAudience } from '../../../constants/cloudVotingConstants';

export const updateVotingStatus = (uid, agenda_uid, customer_uid, voting_status, request_origin, audience) => {
  return async dispatch => {

    let votingStatusInput = {
      uid: uid,
      agenda_uid : agenda_uid,
      customer_uid : customer_uid,
      voting_status : voting_status,
      request_origin: request_origin
    };
    if(request_origin === VotecastRequestOrigin.CLERK_UPDATE && (voting_status === 'N/A' || voting_status === '')) {
      votingStatusInput = {
        ...votingStatusInput,
        motion_result_name: '',
      };
    }
    try {
      const authParameter = { customerId: customer_uid, audience: audience };
      await invokeGraphqlOperation(updateVotecastMotion, { input: votingStatusInput }, authParameter);
    } catch (error) {
      dispatch(displayError({ 'message': error.errors[0].message }));
    }
  };
};

export const updateAllVotesYes = (motion_id, voters, cloudVotingParameters) => {
  return async dispatch => {
    try {
      dispatch(
        markAllVotesYes({
          motion_id
        })
      );
      //#GUB-66927 - CloudVoting change: Update the Ballots with corresponding ballot details("Yes") in the votecast DynamoDB table
      let createOrUpdateBallotInputList = [];
      let updateVotecastAgendaItemBulkActionInput = {};
      let authParameter;
      if (cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
        cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED) {
        createOrUpdateBallotInputList = voters.map(voter => ({
          uid: voter.ballot.id,
          customer_uid: cloudVotingParameters.agenda.customer_id,
          agenda_uid: cloudVotingParameters.agenda.id,
          motion_uid: motion_id,
          parent_agenda_item_uid: cloudVotingParameters.selectedItem.id,
          member_uid: voter.id,
          member_name: voter.display_name,
          voting_configuration_name: 'yes',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          request_origin: VotecastRequestOrigin.CLERK_UPDATE
        }));

        updateVotecastAgendaItemBulkActionInput = {
          uid: cloudVotingParameters.selectedItem.id,
          agenda_uid: cloudVotingParameters.agenda.id,
          bulk_action: VotecastBulkAction.CLERK_MARK_VOTES_ALL_YES
        }

        authParameter = { customerId: cloudVotingParameters.agenda.customer_id, audience: VotecastAudience.LIMA_WEB };
      }

      //await markAllVotesYesAPI(motion_id, voters);
      await Promise.all([
        // Conditionally include all GraphQL calls based on isCloudVotingEnabled condition
        markAllVotesYesAPI(motion_id, voters),
        // AppSync doesn't support batch updates, so using BatchPut as an upsert operation.
        // It will insert a new item if the combination of partition and sort keys doesn't exist,
        // or update the existing item if it does.
        ...(cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
          cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED
          ? [
            processChunks(Object.values(createOrUpdateBallotInputList), createVotecastBallots, authParameter)
          ]
          : [])
      ]);
      if (cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
        cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED) {
        await invokeGraphqlOperation(updateVotecastAgendaItemBulkAction, { input: updateVotecastAgendaItemBulkActionInput }, authParameter)
      }
    } catch (error) {
      console.log('voteActions::updateAllVotesYes', error);
      dispatch(displayError({ 'message': error.response.data.error }));
    }
  };
};

export const resetAllVotes = (motion_id, voters, cloudVotingParameters) => {
  return async dispatch => {
    try {
      dispatch(
        resetVotes({
          motion_id,
          isCloudVotingEnabled: cloudVotingParameters.isCloudVotingEnabled
        })
      );
      //#GUB-66927 - CloudVoting change: Update the Ballots with corresponding ballot details(blank) in the votecast DynamoDB table
      let createOrUpdateBallotInputList = [];
      let updateVotecastAgendaItemBulkActionInput = {};
      let authParameter;
      if (cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
        cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED) {
        createOrUpdateBallotInputList = voters.map(voter => ({
          uid: voter.ballot.id,
          customer_uid: cloudVotingParameters.agenda.customer_id,
          agenda_uid: cloudVotingParameters.agenda.id,
          motion_uid: motion_id,
          parent_agenda_item_uid: cloudVotingParameters.selectedItem.id,
          member_uid: voter.id,
          member_name: voter.display_name,
          voting_configuration_name: '',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          request_origin: VotecastRequestOrigin.CLERK_UPDATE
        }));

        updateVotecastAgendaItemBulkActionInput = {
          uid: cloudVotingParameters.selectedItem.id,
          agenda_uid: cloudVotingParameters.agenda.id,
          bulk_action: VotecastBulkAction.CLERK_RESET_VOTES
        }

        authParameter = { customerId: cloudVotingParameters.agenda.customer_id, audience: VotecastAudience.LIMA_WEB };
      }
      // await resetVotesAPI(motion_id, voters);
      await Promise.all([
        // Conditionally include all GraphQL calls based on isCloudVotingEnabled condition
        resetVotesAPI(motion_id, voters, cloudVotingParameters.isCloudVotingEnabled),
        // AppSync doesn't support batch updates, so using BatchPut as an upsert operation.
        // It will insert a new item if the combination of partition and sort keys doesn't exist,
        // or update the existing item if it does.
        ...(cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
          cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED
          ? [
            processChunks(Object.values(createOrUpdateBallotInputList), createVotecastBallots, authParameter)
          ]
          : [])
      ]);
      if (cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
        cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED) {
        await invokeGraphqlOperation(updateVotecastAgendaItemBulkAction, { input: updateVotecastAgendaItemBulkActionInput }, authParameter);
      }
    } catch (error) {
      console.log('voteActions::resetAllVotes', error);
      dispatch(displayError({ 'message': error.response.data.error }));
    }
  };
};

export const resetAllVotesFromChair = (motion_id, voters, cloudVotingParameters) => {
  return async dispatch => {
    try {
      dispatch(
        resetVotes({
          motion_id,
          isCloudVotingEnabled: cloudVotingParameters.isCloudVotingEnabled
        })
      );
      await resetVotesAPI(motion_id, voters, cloudVotingParameters.isCloudVotingEnabled);
    } catch (error) {
      console.log('voteActions::resetAllVotes', error);
      // dispatch(displayError({ 'message': error.response.data.error }));
    }
  };
};

export const setVotePassageRule = (motion_id, vote_passage_rule, cloudVotingParameters) => {
  return async dispatch => {
    try {
      dispatch(
        updateVotePassageRule({
          motion_id,
          vote_passage_rule
        })
      );

      //#GUB-66927 - CloudVoting change: Update the Motion with corresponding vote passage rule
      // and create master entry for vote passage rule in the votecast DynamoDB table
      let CreateOrUpdateMotionInput = [];
      let createVotecastMotionPassageRuleInput = [];
      let authParameter;
      if (cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
        cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED) {
        CreateOrUpdateMotionInput = {
          uid: motion_id,
          customer_uid: cloudVotingParameters.agenda.customer_id,
          agenda_uid: cloudVotingParameters.agenda.id,
          motion_passage_rule_name: vote_passage_rule,
          request_origin: VotecastRequestOrigin.CLERK_UPDATE
        };

        createVotecastMotionPassageRuleInput = {
          uid: uuidv4(),
          customer_uid: cloudVotingParameters.agenda.customer_id,
          rule: vote_passage_rule
        };

        authParameter = { customerId: cloudVotingParameters.agenda.customer_id, audience: VotecastAudience.LIMA_WEB };
      }
      // await setVotePassageRuleAPI(motion_id, vote_passage_rule);

      await Promise.all([
        // Conditionally include all GraphQL calls based on isCloudVotingEnabled condition
        setVotePassageRuleAPI(motion_id, vote_passage_rule),
        ...(cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
          cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED
          ? [
            invokeGraphqlOperation(updateVotecastMotion, { input: CreateOrUpdateMotionInput }, authParameter),
            invokeGraphqlOperation(createMotionPassageRule, { input: createVotecastMotionPassageRuleInput }, authParameter)
          ]
          : [])
      ]);
    } catch (error) {
      console.log('voteActions::setVotePassageRule', error);
      dispatch(displayError({ 'message': error.response.data.error }));
    }
  };
};

export const setVoteResult = (motion_id, vote_result, cloudVotingParameters) => {
  return async dispatch => {
    try {
      dispatch(
        updateVoteResult({
          motion_id,
          vote_result
        })
      );


      //#GUB-66927 - CloudVoting change: Update the Motion with corresponding vote result and
      // create master entry for vote result in the votecast DynamoDB table
      let CreateOrUpdateMotionInput = [];
      let createVotecastMotionResultInputList = [];
      let authParameter;
      if (cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
        cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED) {
        CreateOrUpdateMotionInput = {
          uid: motion_id,
          customer_uid: cloudVotingParameters.agenda.customer_id,
          agenda_uid: cloudVotingParameters.agenda.id,
          motion_result_name: vote_result,
          request_origin: VotecastRequestOrigin.CLERK_UPDATE
        };
        createVotecastMotionResultInputList = [{
          uid: uuidv4(),
          customer_uid: cloudVotingParameters.agenda.customer_id,
          name: vote_result,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }];

        authParameter = { customerId: cloudVotingParameters.agenda.customer_id, audience: VotecastAudience.LIMA_WEB };
      }
      //  await setVoteResultAPI(motion_id, vote_result);

      await Promise.all([
        // Conditionally include all GraphQL calls based on isCloudVotingEnabled condition
        setVoteResultAPI(motion_id, vote_result),
        ...(cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
          cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED
          ? [
            invokeGraphqlOperation(updateVotecastMotion, { input: CreateOrUpdateMotionInput }, authParameter),
            invokeGraphqlOperation(createMotionResults, { input: createVotecastMotionResultInputList }, authParameter)
          ]
          : [])
      ]);
    } catch (error) {
      console.log('voteActions::setVoteResult', error);
      dispatch(displayError({ 'message': error.response.data.error }));
    }
  };
};

export const setVoterBallot = (motion_id, voter, cloudVotingParameters) => {
  return async dispatch => {
    try {
      dispatch(
        updateVoterBallot({
          motion_id,
          voter
        })
      );

      //#GUB-66927 - CloudVoting change: Update the Ballot with corresponding vote data in the votecast DynamoDB table
      let createOrUpdateBallotInput = [];
      let authParameter;
      if (cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
        cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED) {
        createOrUpdateBallotInput = {
          uid: voter.ballot.id,
          customer_uid: cloudVotingParameters.agenda.customer_id,
          agenda_uid: cloudVotingParameters.agenda.id,
          motion_uid: motion_id,
          parent_agenda_item_uid: cloudVotingParameters.selectedItem.id,
          voting_configuration_name: voter.ballot.action,
          request_origin: VotecastRequestOrigin.CLERK_UPDATE
        };

        authParameter = { customerId: cloudVotingParameters.agenda.customer_id, audience: VotecastAudience.LIMA_WEB };
      }

      await Promise.all([
        // Conditionally include all GraphQL calls based on isCloudVotingEnabled condition
        updateVoterBallotAPI(voter.ballot.id, voter.ballot.action),
        ...(cloudVotingParameters.isCloudVotingEnabled && cloudVotingParameters?.votecastMeetingStatus &&
          cloudVotingParameters.votecastMeetingStatus !== MeetingStatus.STOPPED
          ? [
            invokeGraphqlOperation(updateVotecastBallot, { input: createOrUpdateBallotInput }, authParameter)
          ]
          : [])
      ]);
    } catch (error) {
      console.log('voteActions::setVoterBallot', error);
      dispatch(displayError({ 'message': error.response.data.error }));
    }
  };
};

export const setVoterBallotInLiveManager = (motion_id, voter) => {
  return async dispatch => {
    try {
      dispatch(updateVoterBallot({ motion_id, voter }));
      await updateVoterBallotAPI(voter.ballot.id, voter.ballot.action);
    } catch (error) {
      console.log('voteActions::setVoterBallot', error);
      dispatch(displayError({ 'message': error.response.data.error }));
    }
  };
};
