import {
  createSlice,
  createSelector
} from '@reduxjs/toolkit';

const initialState = [
  // debug data
  // {
  //   motion_id: '',
  //   voters: [
  //   {
  //     display_name: 'Leo TestVannini',
  //     id: <speaker_id>,
  //     ballot: {
  //        id: '',
  //        action: 'abstain'
  //     }
  //   },
  //   vote_result: 'none',
  //   vote_passage_rule: 'Majority'
  // },
];

const votes = createSlice({
  name: 'votes',
  initialState,
  reducers: {
    addVote: (state, action) => {
      return [
        ...state,
        {
          motion_id: action.payload.motion_id,
          voters: action.payload.voters.map(voter => ({ ...voter, ballot: { id: 'empty', action: action.payload.isCloudVotingEnabled ? '' : 'abstain' } })),
          vote_result: 'None',
          vote_passage_rule: 'Majority'
        }
      ];
    },
    deleteVoteByMotionId: (state, action) => {
      return state.filter(vote => vote.motion_id !== action.payload.motion_id);
    },
    markAllVotesYes: (state, action) => {
      let voters = [
        ...state.find(vote => vote.motion_id === action.payload.motion_id).voters
      ].map(voter => {
        return {
          ...voter,
          ballot: {
            ...voter.ballot,
            action: 'yes'
          }
        };
      });

      return state.map(vote => {
        if (vote.motion_id === action.payload.motion_id) {
          return {
            ...vote,
            voters
          };
        } else {
          return vote;
        }
      });
    },
    receiveVotes: (_state, action) => {
      return action.payload;
    },
    resetVotes: (state, action) => {
      let isCloudVotingEnabled = action.payload.isCloudVotingEnabled;
      let voters = [
        ...state.find(vote => vote.motion_id === action.payload.motion_id).voters
      ].map(voter => {
        return {
          ...voter,
          ballot: {
            ...voter.ballot,
            action: isCloudVotingEnabled ? '' : 'abstain'
          }
        };
      });
      return state.map(vote => {
        if (vote.motion_id === action.payload.motion_id) {
          return {
            ...vote,
            voters
          };
        } else {
          return vote;
        }
      });
    },
    syncVoteData: (state, action) => {
      return state.map(vote => {
        if (vote.motion_id === action.payload.motion_id) {
          return {
            ...vote,
            motion_id: action.payload.motion_id,
            voters: vote.voters.map(voter => {
              const ballot = action.payload.data.find(ballot => ballot.member_id === voter.id);
              return {
                ...voter,
                ballot: {
                  id: ballot.id,
                  action: ballot.action
                }
              };
            })
          };
        } else {
          return vote;
        }
      });
    },
    updateVotePassageRule: (state, action) => {
      return state.map(vote => {
        if (vote.motion_id === action.payload.motion_id) {
          return {
            ...vote,
            vote_passage_rule: action.payload.vote_passage_rule
          };
        } else {
          return vote;
        }
      });
    },
    updateVoteResult: (state, action) => {
      return state.map(vote => {
        if (vote.motion_id === action.payload.motion_id) {
          return {
            ...vote,
            vote_result: action.payload.vote_result
          };
        } else {
          return vote;
        }
      });
    },
    updateVoterBallot: (state, action) => {
      let voters = [
        ...state.find(vote => vote.motion_id === action.payload.motion_id).voters
      ].map(voter => {
        if (voter.id === action.payload.voter.id) {
          return {
            ...voter,
            ballot: {
              ...voter.ballot,
              action: action.payload.voter.ballot.action
            }
          };
        } else {
          return voter;
        }
      });

      return state.map(vote => {
        if (vote.motion_id === action.payload.motion_id) {
          return {
            ...vote,
            voters
          };
        } else {
          return vote;
        }
      });
    },
  }
});

export const selectVote = createSelector(
  [state => state.votes, (state,motion_id) => motion_id],
  (votes, motion_id) => votes.find(vote => vote.motion_id === motion_id)
);

export const selectVoteFromMotionId = (motion_id, votes) => {
  return votes.find(vote => vote.motion_id === motion_id);
};

export const selectInProgressVoteItemsCurrentItem = (childItems) => {
  return childItems?.filter(childItem => childItem?.type?.toLowerCase() === 'vote' && !childItem?.timestamp) || [];
};

export const {
  addVote,
  deleteVoteByMotionId,
  markAllVotesYes,
  receiveVotes,
  resetVotes,
  syncVoteData,
  updateVotePassageRule,
  updateVoteResult,
  updateVoterBallot
} = votes.actions;

export default votes.reducer;