import axios from 'axios';

import {
  HYPATIA_BASE_VOTES_URL,
  HYPATIA_BASE_MOTIONS_URL
} from '../../constants/hypatiaServiceConfig';

export const markAllVotesYesAPI = async(motion_id, voters) => {
  const response = await axios.post(
    `${HYPATIA_BASE_MOTIONS_URL}/${motion_id}/all_yes`,
    {
      voters
    }
  );
  return await response.data;
};

export const resetVotesAPI = async(motion_id, voters, is_cloudvoting = false) => {
  const response = await axios.post(
    `${HYPATIA_BASE_MOTIONS_URL}/${motion_id}/reset_votes`,
    {
      voters,
      is_cloudvoting
    }
  );
  return await response.data;
};

export const setVotePassageRuleAPI = async(motion_id, vote_passage_rule) => {
  const response = await axios.post(
    `${HYPATIA_BASE_MOTIONS_URL}/${motion_id}/motion_passage_rule`,
    {
      vote_passage_rule
    }
  );
  return await response.data;
};

export const setVoteResultAPI = async(motion_id, vote_result) => {
  const response = await axios.post(
    `${HYPATIA_BASE_MOTIONS_URL}/${motion_id}/motion_result`,
    {
      vote_result
    }
  );
  return await response.data;
};

export const updateVoterBallotAPI = async(id, action) => {
  const response = await axios.put(
    `${HYPATIA_BASE_VOTES_URL}/${id}`,
    {
      ballot: {
        action
      }
    }
  );
  return await response.data;
};

export const deleteBallotsForVoteAPI = async(motion_id) => {
  const response = await axios.get(
    `${HYPATIA_BASE_MOTIONS_URL}/${motion_id}/ballots`
  );
  let ballots = await response.data;
  let responses = [];
  for (const ballot of ballots) {
    let response = await axios.delete(`${HYPATIA_BASE_VOTES_URL}/${ballot.id}`);
    responses.push(await response.data);
  }
  return responses;
};