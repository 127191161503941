// Mema base api url.
export const HYPATIA_BASE_SERVICE_URL = '/services/minutes';

// Agenda endpoints.
export const HYPATIA_BASE_AGENDAS_URL = `${HYPATIA_BASE_SERVICE_URL}/agendas`;
export const HYPATIA_AGENDA_LIMAWEB_URL = `${HYPATIA_BASE_AGENDAS_URL}/limaweb`;

// Event endpoints.
export const HYPATIA_BASE_EVENT_URL = `${HYPATIA_BASE_SERVICE_URL}/event`;
export const HYPATIA_START_EVENT_URL = `${HYPATIA_BASE_EVENT_URL}/start`;
export const HYPATIA_STOP_EVENT_URL = `${HYPATIA_BASE_EVENT_URL}/stop`;
export const HYPATIA_PAUSE_EVENT_URL = `${HYPATIA_BASE_EVENT_URL}/pause`;
export const HYPATIA_RESUME_EVENT_URL = `${HYPATIA_BASE_EVENT_URL}/resume`;
export const HYPATIA_EXTEND_EVENT_URL = `${HYPATIA_BASE_EVENT_URL}/extend`;

// Clip endpoints.
export const HYPATIA_BASE_CLIP_URL = `${HYPATIA_BASE_SERVICE_URL}/clip`;
export const HYPATIA_INDEX_CURRENT_ITEM_URL = `${HYPATIA_BASE_CLIP_URL}/index-item`;
export const HYPATIA_EXPORT_MINUTES_URL = `${HYPATIA_BASE_CLIP_URL}/export-minutes`;

// Members endpoints.
export const HYPATIA_BASE_MEMBERS_URL = `${HYPATIA_BASE_SERVICE_URL}/members`;

// Items endpoints.
export const HYPATIA_BASE_ITEMS_URL = `${HYPATIA_BASE_SERVICE_URL}/items`;

// Motions endpoints
export const HYPATIA_BASE_MOTIONS_URL = `${HYPATIA_BASE_SERVICE_URL}/motions`;

// Speakers endpoints.
export const HYPATIA_BASE_SPEAKERS_URL = `${HYPATIA_BASE_SERVICE_URL}/speakers`;

// Votes (ballots) endpoints.
export const HYPATIA_BASE_VOTES_URL = `${HYPATIA_BASE_SERVICE_URL}/ballots`;