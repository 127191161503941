import React from 'react';
import ReactModal from 'react-modal';
import { useSelector } from "react-redux";
import { selectIsCloudVotingEnabled } from "../../../common/settingSlice";

const getParent = () => document.querySelector("#agenda-content-container");

const VotingResultModal = ({ isOpen, onClose, votingResult }) => {

  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);
  if (!isCloudVotingEnabled) {
    return null;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      parentSelector={getParent}
      className="voting-result-modal-content"
      overlayClassName="voting-result-modal-overlay"
      ariaHideApp={false} // Ensure this is set to false if you are not using the app element
      shouldCloseOnOverlayClick={false}
    >
      <div className="voting-result-section">
        <h2>Voting Results</h2>
        {votingResult === 'Passed' ? (
          <div className="result-banner success">
            <i className="fa fa-check-circle  check-circle-black" aria-hidden="true"></i> Motion Passed
          </div>
        ) : (
          <div className="result-banner error">
            <i className="fa fa-times-circle times-circle-black" aria-hidden="true"></i> Motion Failed
          </div>
        )}
        <div className="voting-section-footer">
          <div className="close-button-container">
            <button className="btn-white-background-blue-border" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

VotingResultModal.displayName = "VotingResultModal";

export default VotingResultModal;