import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedItemKey,
  selectSelectedItem,
  selectNote,
  setNote,
  selectItemKeys,
  selectAgendaBookmarks,
  setSelectedItemKey,
} from '../activeAgendaSlice';
import {
  Button,
  CardHeader,
  CardBody,
  Textarea,
} from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark as faBookmarkOutline,
  faComment,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBookmark,
  faExclamationCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import Divider from '../../Common/Divider';
import AttachmentList from '../Attachment/AttachmentList';
import {
  useAddBookmarkMutation,
  useDeleteBookmarkMutation,
} from '../../../services/bookmarkApi';
import CommentModal from '../CommentModal/CommentModal';
import { useUpsertNoteMutation } from '../../../services/noteApi';
import Motion from '../../LiveMeeting/Motion';
import { selectLiveMeeting } from '../../../common/liveMeetingSlice';
import { selectIsCloudVotingEnabled } from '../../../common/settingSlice';
import VotingModal from '../../LiveMeeting/Voting/VotingModal';
import SpeakerContainerInDetail from '../../LiveMeeting/Speakers/SpeakerContainerInDetail';

export default function AgendaItemDetail() {
  const dispatch = useDispatch();

  const selectedItemKey = useSelector(selectSelectedItemKey);
  const itemKeys = useSelector(selectItemKeys);
  const bookmarks = useSelector(selectAgendaBookmarks);
  const hasBookmark = bookmarks.findIndex((b) => b === selectedItemKey) >= 0;

  const [commentModalOpen, setCommentModalOpen] = useState(false);

  const savedNote = useSelector((state) => selectNote(state, selectedItemKey));
  const [note, setNote] = useState(savedNote);

  const liveMeeting = useSelector(selectLiveMeeting);
  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);

  const shouldShowMotion =
    isCloudVotingEnabled && liveMeeting.currentItemGuid === selectedItemKey;

  const currentActiveAgendaId = useSelector(
    (state) => state.activeAgenda?.agenda?.uid
  );
  const isMemberJoined = useSelector(
    (state) => state.liveMeeting.memberDetails?.memberJoinStatus
  );
  const isAttendeePresent = useSelector(
    (state) => state.liveMeeting.memberDetails?.isAttendeePresent
  );
  const shouldShowSpeaker =
    isCloudVotingEnabled && isMemberJoined && isAttendeePresent && liveMeeting.meetingGuid === currentActiveAgendaId;

  useEffect(() => {
    setNote(savedNote);
  }, [savedNote]);

  const [
    addBookmark,
    { isLoading: isAddBookmarkLoading },
  ] = useAddBookmarkMutation();
  const [
    deleteBookmark,
    { isLoading: isDeleteBookmarkLoading },
  ] = useDeleteBookmarkMutation();

  const [
    upsertNote,
    { isLoading: isUpsertNoteLoading },
  ] = useUpsertNoteMutation();
  const item = useSelector((state) =>
    selectSelectedItem(state, selectedItemKey)
  );

  const handleNoteChange = (event) => {
    const newNote = event.target.value;
    setNote(newNote);
  };

  const handleSubmitNote = () => {
    upsertNote({ uid: selectedItemKey, note: note });
  };

  const SaveNoteButton = () => {
    return (
      <Button
        className={'item-save-note-button'}
        type={'button'}
        onClick={handleSubmitNote}
        aria-label={'Submit note'}
        disabled={isUpsertNoteLoading || savedNote === note}
      >
        Save Note
      </Button>
    );
  };

  const NoteSaveStatus = () => {
    if (note && savedNote === note && isUpsertNoteLoading === false) {
      // If note is not empty, and that is what is saved, and an update isn't in flight
      return (
        <span
          className={'item-note-save-status saved'}
          aria-label={'Save status'}
        >
          <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 2.5 }} />
          saved
        </span>
      );
    } else if (savedNote !== note) {
      // If note is different than what has been saved last
      return (
        <span
          className={'item-note-save-status unsaved'}
          aria-label={'Save status'}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{ marginRight: 2.5 }}
          />
          not saved
        </span>
      );
    } else {
      // If note is empty
      return <></>;
    }
  };

  const isFirstItem = () =>
    !item || itemKeys.findIndex((i) => i === selectedItemKey) === 0;
  const isLastItem = () =>
    !item ||
    itemKeys.findIndex((i) => i === selectedItemKey) === itemKeys.length - 1;

  const handleNextClick = () => {
    if (isLastItem()) {
      return;
    }
    const nextIndex = itemKeys.findIndex((i) => i === selectedItemKey) + 1;
    dispatch(setSelectedItemKey(itemKeys[nextIndex]));
  };

  const handlePrevClick = () => {
    if (isFirstItem()) {
      return;
    }
    const prevIndex = itemKeys.findIndex((i) => i === selectedItemKey) - 1;
    dispatch(setSelectedItemKey(itemKeys[prevIndex]));
  };

  const handleClickBookmark = async() => {
    try {
      if (hasBookmark) {
        await deleteBookmark(selectedItemKey).unwrap();
      } else {
        await addBookmark(selectedItemKey).unwrap();
      }
    } catch {
      console.error("We couldn't add/remove bookmark, please try again later!");
    }
  };

  const handleOpenCommentModal = () => {
    setCommentModalOpen(true);
  };

  const handleCloseCommentModal = () => {
    setCommentModalOpen(false);
  };

  const prevButton = (
    <Button
      type="button"
      className="item-nav-button"
      unstyled
      onClick={handlePrevClick}
      disabled={isFirstItem()}
    >
      <FontAwesomeIcon
        icon={'angle-left'}
        size={'lg'}
        style={{ marginRight: 4 }}
      />
      <span>Previous Item</span>
    </Button>
  );

  const nextButton = (
    <Button
      type="button"
      className="item-nav-button"
      unstyled
      onClick={handleNextClick}
      disabled={isLastItem()}
    >
      <span>Next Item</span>
      <FontAwesomeIcon
        icon={'angle-right'}
        size={'lg'}
        style={{ marginLeft: 4 }}
      />
    </Button>
  );

  const isBookmarking = isAddBookmarkLoading || isDeleteBookmarkLoading;

  const addBookmarkButton = (
    <Button
      type="button"
      unstyled
      className="text-no-underline item-nav-button"
      onClick={handleClickBookmark}
      title={hasBookmark ? 'Click to remove bookmark' : 'Click to bookmark'}
      disabled={isBookmarking}
    >
      <FontAwesomeIcon
        icon={hasBookmark ? faBookmark : faBookmarkOutline}
        size={'lg'}
        style={{ marginRight: 4 }}
      />
      <span>{hasBookmark ? 'Remove Bookmark' : 'Add Bookmark'}</span>
    </Button>
  );

  const renderCommentInfo = () => {
    if (item?.comments?.length) {
      return (
        <Button
          unstyled
          className="text-no-underline item-nav-button"
          onClick={handleOpenCommentModal}
        >
          <FontAwesomeIcon
            icon={faComment}
            size={'lg'}
            style={{ marginRight: 4 }}
          />
          <span>
            {item.comments.length} Public Comment
            {item.comments.length === 1 ? '' : 's'}
          </span>
        </Button>
      );
    } else {
      return (
        <div>
          <FontAwesomeIcon
            icon={faComment}
            size={'lg'}
            style={{ marginRight: 4 }}
          />
          <span>No Public Comments</span>
        </div>
      );
    }
  };

  const renderCommentModal = () => {
    if (item?.comments?.length) {
      return (
        <CommentModal
          isOpen={commentModalOpen}
          onClose={handleCloseCommentModal}
          comments={item?.comments}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderVotingModal = () => {
    return <VotingModal />;
  };

  const generalInfo = (
    <>
      <div className="display-flex">
        <div className="flex-1">
          <span className="text-base-dark text-normal">Item Title</span>
        </div>
        <div className="flex-auto margin-x-2">{renderCommentInfo()}</div>
        <div className="flex-auto">{addBookmarkButton}</div>
      </div>
      <p
        title="Item Title"
        className="text-base-darkest text-normal margin-top-1"
        id="item-label"
      >
        {item?.name}
      </p>
    </>
  );

  const suggestedAction = (
    <div className="display-flex flex-column margin-top-2">
      <span className="text-base-dark text-normal">Suggested action</span>
      <p id="item-actions">
        {item?.actions
          ? item.actions
          : 'There is no suggested action for this item.'}
      </p>
    </div>
  );

  const notes = (
    <div className="display-flex flex-column margin-top-2">
      <label className="text-base-dark text-normal" htmlFor="input-note-text">
        My Notes
      </label>
      <div className={'note-text-container'}>
        <Textarea
          id="input-note-text"
          name="input-note-text"
          className="maxw-full item-note-text-area"
          value={note || ''}
          onChange={handleNoteChange}
          aria-label={'Note text area'}
        />
        <NoteSaveStatus />
      </div>
      <SaveNoteButton />
    </div>
  );

  const motion = (
    <>
      {shouldShowMotion && (
        <>
          <Divider color="#DCDEE0" size="1" />
          <Motion />
        </>
      )}
    </>
  );

  const speaker = (
    <>
      {shouldShowSpeaker && (
        <>
          <Divider color="#DCDEE0" size="1" />
          <SpeakerContainerInDetail />
        </>
      )}
    </>
  );

  return (
    <>
      {item && (
        <div className="agenda-item-detail-tab margin-top-1">
          {generalInfo}
          <Divider color="#DCDEE0" size="1" />
          {suggestedAction}
          {motion}
          {speaker}

          <Divider color="#DCDEE0" size="1" />
          <AttachmentList attachments={item.attachments} />
          <Divider color="#DCDEE0" size="1" />
          {notes}
        </div>
      )}

      {renderCommentModal()}
      {renderVotingModal()}
    </>
  );
}

AgendaItemDetail.displayName = 'AgendaItemDetail';
