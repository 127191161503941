export const getAgendaList = `
    query getVotecastAgendaByCustomerId($customer_uid: String!) {
        getVotecastAgendaByCustomerId(customer_uid: $customer_uid) {
            uid
            customer_uid
            meeting_status
            meeting_guid
        }
    }
`;

export const getVotecastAgendaById = `
    query getVotecastAgendaById($customer_uid: String!, $uid: ID!) {
        getVotecastAgendaById(customer_uid: $customer_uid, uid: $uid) {
            uid
            customer_uid
            meeting_status
            meeting_guid
        }
    }
`;

export const getAgendaListByMeetingGuid = `
    query getVotecastAgendaByMeetingGuid($customer_uid: String!, $meeting_guid: String!) {
        getVotecastAgendaByMeetingGuid(customer_uid: $customer_uid, meeting_guid: $meeting_guid) {
            uid
            customer_uid
            meeting_status
            meeting_guid
            created_at
            title
            starts_at
            meeting_status_timestamp
        }
    }
`;

export const getMembersByCustomerIdDisplayName = `
    query getVotecastMembersByCustomerIdDisplayName($customer_uid: String!, $person_uid: String!, $display_name: String!) {
        getVotecastMembersByCustomerIdDisplayName(customer_uid: $customer_uid, person_uid: $person_uid, display_name: $display_name) {
            uid
            customer_uid
            agenda_uid
            display_name
            status
            availability_status
            person_uid
            chair
            voting
        }
    }
`;

export const getMembersByDisplayName = `
    query getVotecastMembersByDisplayName($customer_uid: String!, $agenda_uid: String!, $person_uid: String!, $display_name: String!) {
        getVotecastMembersByDisplayName(customer_uid: $customer_uid, agenda_uid: $agenda_uid, person_uid: $person_uid, display_name: $display_name) {
            uid
            customer_uid
            agenda_uid
            display_name
            status
            availability_status
            person_uid
            chair
            voting
        }
    }
`;

export const getVotecastAgendaItems = `
    query getVotecastAgendaItems($agenda_uid: String!) {
        getVotecastAgendaItems(agenda_uid: $agenda_uid) {
            uid
            status
            mema_metadata_id
            title
            type
            entrytime
            parent_uid
            metadata
            position
        }
    }
`;

export const getActiveAgendaItem = `
    query getVotecastActiveAgendaItem($agenda_uid: String!) {
        getVotecastActiveAgendaItem(agenda_uid: $agenda_uid) {
            customer_uid
            agenda_uid
            uid
            status
            mema_metadata_id
            title
            metadata
            entrytime
        }
    }
`;

export const getMotionByAgendaItemId = `
    query getVotecastMotionByAgendaItemId($agenda_uid: String!, $parent_agenda_item_uid: String!) {
        getVotecastMotionByAgendaItemId(agenda_uid: $agenda_uid, parent_agenda_item_uid: $parent_agenda_item_uid) {
            customer_uid
            agenda_uid
            uid
            position
            motion_action_name
            motioner_uid
            seconder_uid
            motioner_name
            seconder_name
            request_origin
            entrytime
            created_at
            voting_status
        }
    }
`;

export const getVotecastCustomerId = `
    query getVotecastCustomerId($domain: String!) {
        getVotecastCustomerId(domain: $domain) {
            domain
            uid
        }
    }
`;

export const getBallotByParentItemId = `
    query getVotecastBallotByParentItemId($parent_agenda_item_uid: String!) {
        getVotecastBallotByParentItemId(parent_agenda_item_uid: $parent_agenda_item_uid) {
            uid
            motion_uid
            member_uid
            member_name
            parent_agenda_item_uid
            voting_configuration_name
        }
    }
`;

export const getVotecastMotionById = `
    query getVotecastMotionById($agenda_uid: String!, $uid: String!) {
        getVotecastMotionById(agenda_uid: $agenda_uid, uid: $uid) {
            customer_uid
            agenda_uid
            uid
            motion_action_name
            motioner_uid
            seconder_uid
            motioner_name
            seconder_name
            text
            motion_result_name
            voting_status
            request_origin
        }
    }
`;

export const getVotecastSpeakersByAgendaId = `
    query getVotecastSpeakersByAgendaId($agenda_uid: String!, $nextToken: String) {
        getVotecastSpeakersByAgendaId(agenda_uid: $agenda_uid, nextToken: $nextToken) {
            items {
                customer_uid
                agenda_uid
                uid
                name
                speaker_type
                duration
                item_uid
                position
                specialPosition
                start_time
                status
                request_origin
            }
            nextToken
        }
    }
`;

export const getVotecastClipsByAgendaUid = `
    query getVotecastClipsByAgendaUid($customer_uid: String!, $agenda_uid: String!) {
        getVotecastClipsByAgendaUid(customer_uid: $customer_uid, agenda_uid: $agenda_uid) {
            uid,
            customer_uid,
            agenda_uid
        }
    }
`;

export const getVotecastAgendaItemsByAgendaUid = `
    query getVotecastAgendaItemsByAgendaUid($customer_uid: String!, $agenda_uid: String!) {
        getVotecastAgendaItemsByAgendaUid(customer_uid: $customer_uid, agenda_uid: $agenda_uid) {
            uid,
            title,
            customer_uid,
            agenda_uid,
            parent_uid,
            position
        }
    }
`;

export const getVotecastMotionsByAgendaUid = `
    query getVotecastMotionsByAgendaUid($customer_uid: String!, $agenda_uid: String!) {
        getVotecastMotionsByAgendaUid(customer_uid: $customer_uid, agenda_uid: $agenda_uid) {
            uid,
            customer_uid,
            agenda_uid,
            voting_status
        }
    }
`;

export const getVotecastMembersByAgendaUid = `
    query getVotecastMembersByAgendaUid($customer_uid: String!, $agenda_uid: String!) {
        getVotecastMembersByAgendaUid(customer_uid: $customer_uid, agenda_uid: $agenda_uid) {
            uid,
            customer_uid,
            agenda_uid,
            display_name,
            person_uid,
            availability_status,
            status,
            voting,
            chair,
            created_at
        }
    }
`;

export const getVotecastAgendaItemsByParentUid = `
    query getVotecastAgendaItemsByParentUid($parent_uid: String!, $type: String!) {
        getVotecastAgendaItemsByParentUid(parent_uid: $parent_uid, type: $type) {
            uid,
            customer_uid,
            agenda_uid,
            parent_uid,
            position,
            entrytime,
            type,
            metadata,
            updated_at
        }
    }
`;