import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetActiveAgendaQuery } from "../../services/agendaApi";
import { useGetActiveAgendaBookmarksQuery } from "../../services/bookmarkApi";
import { useGetActiveAgendaNotesQuery } from "../../services/noteApi";
import { useGetCurrentUserQuery /*, useGetCurrentPersonUidQuery */ } from '../../services/authApi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setActiveAgenda,
  setItemKeys,
  setItemsDictionary,
  setSelectedItemKey,
  resetData,
  selectEnableErrorNotification,
  setEnableActiveAgendaErrorNotification
} from "./activeAgendaSlice";
import { Card, CardGroup, Button } from "@trussworks/react-uswds";
import AgendaItems from "./AgendaItems";
import { buildAgendaData, validatePacketUrl } from "../../helpers/agendaHelper";
import { getDateInOffsetTime } from "../../helpers/dayjsHelpers";
import settings from "../../common/settings";
import Breadcrumb from "../Navigation/Breadcrumb";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { selectIsCloudVotingEnabled } from "../../common/settingSlice";
import { setCustomerId, setUsername } from '../Auth/authSlice';
import JoinMeetingButton from "../Common/JoinMeetingButton";
import LeaveMeetingButton from "../Common/LeaveMeeting";
import { CvMeetingLiveIcon } from "../../../../assets/images/legislate/imageIndex";
import classNames from "classnames";
import {
  selectLiveMeeting,
  setCurrentItem,
  setLiveMeeting,
  resetData as resetLiveMeetingData,
  setMotionDetails,
  setAgendaItemsDictionary,
  setVoteStop,
  setIsVotingStarted,
  setMemberDetails,
  selectVoteState,
  setVotesChanged,
  setCurrentVote,
  setVoteStarted,
  selectVoteInProgressState,
  setVoteInProgressState,
  setVoteResume,
  setVoteInProgressStop,
  setCanChairStartVote,
  setVotesInProgressChanged,
  setCurrentVoteInProgress
} from "../../common/liveMeetingSlice";
import { setSpeakers, setCalledSpeaker, deleteSpeaker } from '../../common/liveMeetingSpeakerSlice';
import {
  MeetingStatus, AvailabilityStatus, MemberAttendance, VotecastRequestOrigin, AgendaItemActiveStatus,
  AgendaItemType, SpeakerStatus, VotecastBulkAction, VoteActionEnum, VotecastAudience, VotecastVotingStatus
} from "../../../constants/cloudVotingConstants";
import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import {
  getMembersByCustomerIdDisplayName,
  getVotecastAgendaItems,
  getMotionByAgendaItemId,
  getAgendaListByMeetingGuid,
  getVotecastSpeakersByAgendaId,
  getActiveAgendaItem,
  getBallotByParentItemId,
  getVotecastAgendaItemsByParentUid,
  getVotecastMotionById
} from '../../../../amplify/graphql/queries';
import { updateMemberAvailabilityStatus, updateVotecastMotion } from '../../../../amplify/graphql/mutations';
import ErrorNotification from '../Common/ErrorNotification';
import {
  onAgendaItemRecord,
  onMeetingEndUpdate,
  onVotecastMemberUpdate,
  onVotecastCreateDeleteSpeaker,
  onVotecastDeleteAttendee,
  onMotionUpdate,
  onBallotUpdate,
  onCreateUpdateAgendaItemForVote,
  onVotecastDeleteAgendaItem,
  onCreateUpdateAgendaItemForSpeaker
} from '../../../../amplify/graphql/subscriptions';
import { toast } from 'react-toastify';
import Container from "./ItemDetail/Container";
import { invokeGraphqlOperation, invokeGraphqlOperationWithNextToken, getJWTToken } from '../../../helpers/votecastWebHelpers/graphqlOperations';
import VotingModal from '../LiveMeeting/Voting/VotingModal';
import VotingResultModal from '../LiveMeeting/Voting/VotingResultModal';
import { updateVotingStatus } from "../../../components/new_limaweb/actions/voteActions";

const ActiveAgenda = () => {
  const dispatch = useDispatch();
  const { agenda_uid: agendaUid, item_uid: itemUid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [treeData, setTreeData] = useState(undefined);

  const [isMeetingOpenOrRunning, setIsMeetingOpenOrRunning] = useState(false);
  const [isMeetingRunning, setIsMeetingRunning] = useState(false);
  const [memberId, setMemberId] = useState('');
  const personUid = useSelector(state => state.auth.personUid);
  const customerId = useSelector(state => state.auth.customer_uid);
  const userName = useSelector(state => state.auth.username);
  const speakers = useSelector((state) => state.liveMeetingSpeaker.speakers);
  const calledSpeaker = useSelector(state => state.liveMeetingSpeaker.calledSpeaker);
  const voteState = useSelector(selectVoteState);
  const liveMeeting = useSelector(selectLiveMeeting);
  const memberJoinStatus = liveMeeting.memberDetails.memberJoinStatus;
  const speakersListRef = React.useRef(speakers);
  const motionDetailsRef = React.useRef(liveMeeting?.motionDetails);
  const voteStateRef = React.useRef(voteState);
  const calledSpeakerRef = React.useRef(calledSpeaker);
  const currentUserRef = React.useRef(liveMeeting?.memberDetails);
  const voteInProgressState = useSelector(selectVoteInProgressState);
  const voteInProgressStateRef = React.useRef(voteInProgressState);

  const {
    data: agenda,
    isSuccess: isAgendaSuccess,
    isError: isAgendaError,
  } = useGetActiveAgendaQuery(agendaUid);

  const {
    data: bookmarks,
    isSuccess: isBookmarksSuccess,
    isError: isBookmarksError,
  } = useGetActiveAgendaBookmarksQuery(agendaUid);

  const votecast_enableErrorNotification = useSelector(selectEnableErrorNotification);

  const {
    data: notes,
    isSuccess: isNotesSuccess,
    isError: isNotesError,
  } = useGetActiveAgendaNotesQuery(agendaUid);
  const [showVotingResult, setShowVotingResult] = useState(false);
  const [votingResult, setVotingResult] = useState(null); // 'Passed' or 'Failed'

  const handleClose = () => setShowVotingResult(false);

  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);
  const {
    data: currentUserData,
    isSuccess: isCurrentUserSuccess,
    isError: isCurrentUserError,
  } = useGetCurrentUserQuery(undefined, { skip: !isCloudVotingEnabled });

  useEffect(() => {
    if (isCloudVotingEnabled && isCurrentUserSuccess) {
      dispatch(setCustomerId(currentUserData?.customer_uid));
      dispatch(setUsername(currentUserData?.user?.table?.fullname));
    }
  }, [isCloudVotingEnabled, isCurrentUserSuccess])

  useEffect(() => {
    if (isCloudVotingEnabled) {
      speakersListRef.current = speakers;
      motionDetailsRef.current = liveMeeting?.motionDetails;
      voteStateRef.current = voteState;
      calledSpeakerRef.current = calledSpeaker;
      currentUserRef.current = liveMeeting?.memberDetails;
      voteInProgressStateRef.current = voteInProgressState;
    }
  }, [speakers, voteState, liveMeeting?.motionDetails, calledSpeaker, isCloudVotingEnabled, liveMeeting?.memberDetails, voteInProgressState ]);

  /* Commenting out fetching and setting of current person UID based on user name
  const currentUserName = currentUserData ? currentUserData.user?.table?.fullname : '';
  const {
    data: currentPersonUidData,
    isSuccess: isCurrentPersonUidSuccess,
    isError: isCurrentPersonUidError,
  } = useGetCurrentPersonUidQuery(currentUserName, {
    skip: !isCloudVotingEnabled || !currentUserName,
  });
  useEffect(() => {
    if (cloudVotingEnabled && isCurrentPersonUidSuccess ) {
      dispatch(setPersonUid(currentPersonUidData[0]?.uuid));
    }
  }, [isCloudVotingEnabled, isCurrentPersonUidSuccess])*/

  const votecastAgendaUid = liveMeeting.votecastAgendaUid;
  const currentItemHypatiaId = liveMeeting.currentItemHypatiaId;
  const authParameter = { customerId: customerId, audience: VotecastAudience.LEGISLATE };

  useEffect(() => {
    if (isCloudVotingEnabled && memberJoinStatus && customerId && votecastAgendaUid) {
      onJoinMeeting(votecastAgendaUid);
    }
  }, [isCloudVotingEnabled, memberJoinStatus]);

  const onJoinMeeting = async (votecastAgendaUid) => {
    const agendaItemsData = await invokeGraphqlOperation(getVotecastAgendaItems, { agenda_uid: votecastAgendaUid }, authParameter);
    if (agendaItemsData && agendaItemsData.length > 0) {
      const agendaItemsDictionary = agendaItemsData.reduce((acc, item) => {
        acc[item.mema_metadata_id] = item;
        return acc;
      }, {});
      dispatch(setAgendaItemsDictionary(agendaItemsDictionary));

      // get and set the active agenda item
      const activeAgendaItem = agendaItemsData.find(item => item.status === AgendaItemActiveStatus.ACTIVE && item.type === AgendaItemType.Agenda && item.entrytime);
      if (activeAgendaItem) {
        dispatch(setCurrentItem({
          currentItemGuid: activeAgendaItem.mema_metadata_id,
          currentItemName: activeAgendaItem.title,
          currentItemHypatiaId: activeAgendaItem.uid
        }));
        dispatch(setMotionDetails({ allowMotions: true }));

        const activeMotionItem = agendaItemsData.find(item => item.type === AgendaItemType.Motion
          && !item.entrytime && item.parent_uid === activeAgendaItem.uid);

        const parentItemId = activeMotionItem ? activeMotionItem.uid : activeAgendaItem.uid;
        const activeMotionData = await invokeGraphqlOperation(getMotionByAgendaItemId,
          { agenda_uid: votecastAgendaUid, parent_agenda_item_uid: parentItemId }, authParameter);

        if (activeMotionData && activeMotionData.length > 0) {
          const motionInProgress = activeMotionData[0];
          if (motionInProgress) {
            dispatch(setMotionDetails({
              motionId: motionInProgress.uid,
              position: motionInProgress.position,
              motionTo: motionInProgress.motion_action_name,
              motionMover: motionInProgress.motioner_name,
              motionSeconder: motionInProgress.seconder_name
            }));
          }
        }
        // Find the previous motion with the latest Timestamped Motion
        const motionItemsWithEntryTime = agendaItemsData.filter(item => item.type === AgendaItemType.Motion
          && item.entrytime && item.parent_uid === activeAgendaItem.uid);
        let previousMotion = null;
        if (motionItemsWithEntryTime && motionItemsWithEntryTime.length > 0) {
          const latestRecordedMotionItem = motionItemsWithEntryTime
            .sort((a, b) => b.position - a.position)[0];
          if (latestRecordedMotionItem) {
            const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
            if (motionUid?.motion_id) {
              previousMotion = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
              if(previousMotion !== null){
                dispatch(setMotionDetails({
                  previousMotion: {
                    motionTo: previousMotion.motion_action_name,
                    mover: previousMotion.motioner_name,
                    seconder: previousMotion.seconder_name
                  }
                }));
              }
            }
          }
        }

        const voteAgendaItemData = agendaItemsData.find(item => item.parent_uid === activeAgendaItem.uid && item.type === AgendaItemType.Vote && !item.entrytime);
        if (voteAgendaItemData) {
          const voteItemMetadata = JSON.parse(voteAgendaItemData?.metadata ?? "{}");
          const motionData = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: voteItemMetadata?.motion_id }, authParameter);
          if (motionData && motionData.voting_status === VotecastVotingStatus.STARTED) {
            const metadata = JSON.parse(activeAgendaItem?.metadata ?? "{}");
            dispatch(setVoteStarted({
              itemText: activeAgendaItem?.title,
              voteId: voteAgendaItemData.uid,
              motionId: motionData.uid,
              motionText: motionData?.text ?? "",
              motionAction: metadata?.suggested_action ?? "",
              motionTo: motionData?.motion_action_name ?? "",
              motionMover: motionData?.motioner_name ?? "",
              motionSeconder: motionData?.seconder_name ?? ""
            }));
            dispatch(setIsVotingStarted(true));
            const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid }, authParameter);
            if (ballotsData && ballotsData.length > 0) {
              const currentUserVote = ballotsData.find(vote => vote.member_uid === currentUserRef.current.memberId);
              dispatch(setVoteStarted({ ballotId: currentUserVote?.uid }));
              dispatch(setVotesChanged(ballotsData));
              if(currentUserVote){
                dispatch(setCurrentVote(currentUserVote));
              }            
            }
            dispatch(setCanChairStartVote(true));
          } else if(motionData && motionData.voting_status === VotecastVotingStatus.STOPPED) {
            const metadata = JSON.parse(activeAgendaItem?.metadata ?? "{}");
            dispatch(setIsVotingStarted(true));
            dispatch(setVoteStop());
            let ballotsData = null;
            let currentUserVote = null;
            ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid }, authParameter);
            if (ballotsData && ballotsData.length > 0) {
              currentUserVote = ballotsData.find(vote => vote.member_uid === currentUserRef.current.memberId);
            }
            if(ballotsData !== null && currentUserVote !== null) {
              let payload = {
                ballotId: currentUserVote?.uid,
                itemText: activeAgendaItem?.title,
                voteId: voteAgendaItemData.uid,
                motionId: motionData.uid,
                motionText: motionData?.text ?? "",
                motionAction: metadata?.suggested_action ?? "",
                motionTo: motionData?.motion_action_name ?? "",
                motionMover: motionData?.motioner_name ?? "",
                motionSeconder: motionData?.seconder_name ?? "",
                votes : ballotsData,
                currentVote: currentUserVote
              };
              dispatch(setVoteInProgressState(payload));
            }
            dispatch(setCanChairStartVote(true));
          }
        } else {
          // Condition where there is no incomplete vote
          if(previousMotion === null || previousMotion === undefined){
            dispatch(setCanChairStartVote(false));
          } else {
            if(!(previousMotion.seconder_name === 'Failed for lack of second' || previousMotion.seconder_name == "")) {
              if(
                previousMotion?.voting_status === null || 
                previousMotion?.voting_status === "N/A" || 
                previousMotion?.voting_status === ""
              ){
                dispatch(setCanChairStartVote(true));
              } else if(previousMotion?.voting_status === VotecastVotingStatus.COMPLETED) {
                dispatch(setCanChairStartVote(false));
              }
            } else {
              dispatch(setCanChairStartVote(false));
            }
          }
        }
      }
    }

    // get the list of speakers for agenda_id
    const speakersData = await invokeGraphqlOperationWithNextToken(getVotecastSpeakersByAgendaId, { agenda_uid: votecastAgendaUid }, authParameter);
    if (speakersData && speakersData.length > 0) {
      dispatch(setSpeakers(speakersData));
      const calledSpeaker = speakersData.find(sp => sp.status && [SpeakerStatus.CALLED, SpeakerStatus.STARTED, SpeakerStatus.STOPPED].includes(sp.status));
      if (calledSpeaker) {
        dispatch(setCalledSpeaker({
          ...calledSpeaker,
          duration: calledSpeaker.duration === -1 ? 0 : calledSpeaker.duration,
        }));
      }
    }
  };

  const testOnLeaveMeeting = () => {
    dispatch(resetLiveMeetingData());
  };

  // CV TEMP TO BE CHANGED - END

  useEffect(() => {
    if (isAgendaSuccess && isBookmarksSuccess && isNotesSuccess) {
      dispatch(
        setActiveAgenda([
          agenda,
          notes.councilNotesByItemUid,
          bookmarks.bookmarkedItemUids,
        ])
      );

      // combine & build tree data
      const [treeData, itemsDict, keys] = buildAgendaData(
        agenda,
        notes.councilNotesByItemUid,
        bookmarks.bookmarkedItemUids
      );

      setTreeData(treeData);

      dispatch(setItemKeys(keys));

      // build agenda items dictionary to improve the performance while navigating items (next/prev)
      dispatch(setItemsDictionary(itemsDict));

      if (!isItemSelected) {
        setIsItemSelected(true);
        let currentItem;

        if (itemUid) {
          currentItem = itemUid; //if params has itemUid
        } else if (
          liveMeeting.meetingGuid === agendaUid &&
          !!liveMeeting.currentItemGuid
        ) {
          currentItem = liveMeeting.currentItemGuid; //focus to current live Item - realtime
        } else {
          const firstItemKey = keys.length > 0 ? keys[0] : null;
          if (firstItemKey) {
            currentItem = firstItemKey;
          }
        }

        dispatch(setSelectedItemKey(currentItem));
      }
      setIsLoading(false);
    }
  }, [
    agendaUid,
    isAgendaSuccess,
    isBookmarksSuccess,
    isNotesSuccess,
    bookmarks?.bookmarkedItemUids.length,
    notes?.councilNotesByItemUid,
  ]);

  useEffect(() => {
    let isCancelled = false;
    const fetchMeetingData = async () => {
      if (isCloudVotingEnabled && customerId) {
        const meetingData = await invokeGraphqlOperation(getAgendaListByMeetingGuid, { customer_uid: customerId, meeting_guid: agendaUid }, authParameter);
        const filteredAndSortedMeetingData = meetingData
          ?.filter(item => [MeetingStatus.OPEN, MeetingStatus.RUNNING, MeetingStatus.PAUSED].includes(item.meeting_status))
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const liveVotecastAgendaUid = filteredAndSortedMeetingData?.[0]?.uid ?? null;
        dispatch(setLiveMeeting({ meetingGuid: agendaUid, votecastAgendaUid: liveVotecastAgendaUid }));

        if (filteredAndSortedMeetingData?.length > 0) {
          if ([MeetingStatus.RUNNING, MeetingStatus.PAUSED].includes(filteredAndSortedMeetingData[0].meeting_status)) {
            setIsMeetingRunning(true);
          } else {
            setIsMeetingRunning(false);
          }
          // Get the list of meeting for which the logged in user is attendee
          const meetingForAttendee = await invokeGraphqlOperation(getMembersByCustomerIdDisplayName, {
            customer_uid: customerId, person_uid: personUid, display_name: userName}, authParameter);

          if (meetingForAttendee && meetingForAttendee.length > 0) {
            const isAnyOtherMeetingJoined = meetingForAttendee.find(meeting => meeting.availability_status === AvailabilityStatus.JOINED && meeting.agenda_uid !== liveVotecastAgendaUid);
            if (isAnyOtherMeetingJoined) {
              setIsMeetingOpenOrRunning(false);
              return;
            }
            if (!isCancelled) {
              setIsMeetingOpenOrRunning(true);
            }
            const memberData = meetingForAttendee.filter(meeting => meeting.agenda_uid === liveVotecastAgendaUid);
            let personIdMatch = false;
            if (memberData && memberData.length > 0) {
              for (const member of memberData) {
                if (member.person_uid === personUid || member.display_name === userName) {
                  if (!isCancelled) {
                    setMemberId(member.uid);
                    dispatch(
                      setMemberDetails({
                        memberId: member.uid,
                        memberJoinStatus: member.availability_status === AvailabilityStatus.JOINED,
                        isAttendeePresent: member.status && member.status.toLowerCase() === MemberAttendance.PRESENT,
                        isChair: member.chair,
                        isVoter: member.voting,
                      }));
                  }
                  personIdMatch = true;
                  break;
                }
              }
              if (!personIdMatch && !isCancelled) {
                setMemberId(memberData[0].uid);
                dispatch(setMemberDetails({
                  memberId: memberData[0].uid,
                  memberJoinStatus: memberData[0].availability_status === AvailabilityStatus.JOINED,
                  isAttendeePresent: memberData[0].status && memberData[0].status.toLowerCase() === MemberAttendance.PRESENT,
                  isChair: memberData[0].chair,
                  isVoter: memberData[0].voting
                }));
              }
            }
          }
        } else {
          setIsMeetingOpenOrRunning(false);
          setIsMeetingRunning(false);
        }
      }
    };
    fetchMeetingData();
    return () => {
      isCancelled = true;
    };
  }, [isCloudVotingEnabled, customerId, agendaUid, personUid, userName]);

  useEffect(() => {
    let recordAgendaSubscription;
    const startAgendaItemRecordSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        recordAgendaSubscription = await GraphQLAPI.graphql(graphqlOperation(onAgendaItemRecord,
          { customer_uid: customerId, agenda_uid: votecastAgendaUid, status: "Active" }, authToken
        )).subscribe({
          next: async (response) => {
            const agendaItemData = response.value.data.onVotecastAgendaItemRecord;
            if (agendaItemData && agendaItemData.mema_metadata_id) {
              if (currentItemHypatiaId !== agendaItemData.uid) {
                dispatch(setMotionDetails({
                  allowMotions: true, isMotionEnabled: false,
                  motionId: '',
                  motionItemId: '',
                  position: '',
                  motionTo: '',
                  motionMover: '',
                  motionSeconder: '',
                  previousMotion: undefined
                }));
                dispatch(setIsVotingStarted(false));
                dispatch(setVoteStop());
                dispatch(setVoteInProgressStop());
              }
              dispatch(setCurrentItem({
                currentItemGuid: agendaItemData.mema_metadata_id,
                currentItemName: agendaItemData.title,
                currentItemHypatiaId: agendaItemData.uid
              }));
              // Get all motions for active agenda item
              const motionsForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                { parent_uid: agendaItemData.uid, type: AgendaItemType.Motion }, authParameter);
              const latestRecordedMotionItem = motionsForActiveAgendaItem
                .filter(motion => motion.entrytime)
                .sort((a, b) => b.position - a.position)[0];
              let latestRecordedMotionDetail = null;
              if (latestRecordedMotionItem) {
                const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
                if (motionUid?.motion_id) {
                  latestRecordedMotionDetail = await invokeGraphqlOperation(getVotecastMotionById,
                    { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
                  dispatch(setMotionDetails({
                    allowMotions: true, isMotionEnabled: false,
                    motionId: '',
                    motionItemId: '',
                    position: '',
                    motionTo: '',
                    motionMover: '',
                    motionSeconder: '',
                    previousMotion: latestRecordedMotionDetail !== null ? {
                      motionTo: latestRecordedMotionDetail?.motion_action_name,
                      mover: latestRecordedMotionDetail?.motioner_name,
                      seconder: latestRecordedMotionDetail?.seconder_name
                    } : undefined
                  }));
                  const votesForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                    { parent_uid: agendaItemData.uid, type: AgendaItemType.Vote }, authParameter);
                  const incompleteVoteAgendaItemData = votesForActiveAgendaItem.find(vote => !vote.entrytime);
                  if (incompleteVoteAgendaItemData) {
                    const metadata = JSON.parse(incompleteVoteAgendaItemData?.metadata ?? "{}");
                    const voteMotionData = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: metadata?.motion_id }, authParameter);
                    if (voteMotionData && voteMotionData.voting_status === VotecastVotingStatus.STARTED) {
                      dispatch(setVoteStarted({
                        itemText: agendaItemData?.title,
                        voteId: incompleteVoteAgendaItemData.uid,
                        motionId: voteMotionData.uid,
                        motionText: voteMotionData?.text ?? "",
                        motionAction: metadata?.suggested_action ?? "",
                        motionTo: voteMotionData?.motion_action_name ?? "",
                        motionMover: voteMotionData?.motioner_name ?? "",
                        motionSeconder: voteMotionData?.seconder_name ?? ""
                      }));
                      dispatch(setIsVotingStarted(true));
                      const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: incompleteVoteAgendaItemData.uid }, authParameter);
                      if (ballotsData && ballotsData.length > 0) {
                        const currentUserVote = ballotsData.find(vote => vote.member_uid === currentUserRef.current.memberId);
                        dispatch(setVoteStarted({ ballotId: currentUserVote?.uid }));
                        dispatch(setVotesChanged(ballotsData));
                        if(currentUserVote){
                          dispatch(setCurrentVote(currentUserVote));
                        }
                      }
                      dispatch(setCanChairStartVote(true));
                    } else if(voteMotionData && voteMotionData.voting_status === VotecastVotingStatus.STOPPED) {
                      dispatch(setIsVotingStarted(true));
                      dispatch(setVoteStop());
                      let ballotsData = null;
                      let currentUserVote = null;
                      ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: incompleteVoteAgendaItemData.uid }, authParameter);
                      if (ballotsData && ballotsData.length > 0) {
                        currentUserVote = ballotsData.find(vote => vote.member_uid === currentUserRef.current.memberId);
                      }
                      if(ballotsData !== null && currentUserVote !== null) {
                        let payload = {
                          ballotId: currentUserVote?.uid,
                          itemText: agendaItemData?.title,
                          voteId: incompleteVoteAgendaItemData.uid,
                          motionId: voteMotionData.uid,
                          motionText: voteMotionData?.text ?? "",
                          motionAction: metadata?.suggested_action ?? "",
                          motionTo: voteMotionData?.motion_action_name ?? "",
                          motionMover: voteMotionData?.motioner_name ?? "",
                          motionSeconder: voteMotionData?.seconder_name ?? "",
                          votes : ballotsData,
                          currentVote: currentUserVote
                        };
                        dispatch(setVoteInProgressState(payload));
                      }
                      dispatch(setCanChairStartVote(true));
                    }
                  } else {
                    // console.log('latestRecordedMotionDetail on agenda item record', latestRecordedMotionDetail);
                    if(latestRecordedMotionDetail === null || latestRecordedMotionDetail === undefined){
                      dispatch(setCanChairStartVote(false));
                    } else {
                      if(!(latestRecordedMotionDetail.seconder_name === 'Failed for lack of second' || latestRecordedMotionDetail.seconder_name == "")) {
                        if(
                          latestRecordedMotionDetail?.voting_status === null || 
                          latestRecordedMotionDetail?.voting_status === "N/A" || 
                          latestRecordedMotionDetail?.voting_status === ""
                        ){
                          dispatch(setCanChairStartVote(true));
                        } else if(latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.COMPLETED) {
                          dispatch(setCanChairStartVote(false));
                        } 
                        // else if(
                        //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STARTED || 
                        //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STOPPED
                        // ){
                        //   dispatch(setCanChairStartVote(true));
                        //   // Get data for vote to resume and set in voteInProgressState
                        // }
                      } else {
                        dispatch(setCanChairStartVote(false));
                      }
                    }
                  }
                }
              } else {
                dispatch(setCanChairStartVote(false));
              }           
            }
          },
          error: (error) => {
            console.error('Subscription error:', error);
          }
        });
      } catch (error) {
        console.error('Error initializing subscription:', error);
      }
    };
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus) {
      startAgendaItemRecordSubscription();
    }
    return () => {
      if (recordAgendaSubscription) recordAgendaSubscription.unsubscribe();
    };
  }, [isCloudVotingEnabled, customerId, votecastAgendaUid, liveMeeting.currentItemHypatiaId, memberJoinStatus]);

  //#region - #GUB-67045 - Cloudvoting change: speaker subscription for legislate in Dynamo DB
  useEffect(() => {
    let speaker_bulk_subscription;
    let speaker_subscription;

    const handleSubscriptionError = (error, subscribeFunction) => {
      console.error('Error in speaker subscription:', error);
      setTimeout(() => {
        console.log('Attempting to reconnect speaker subscription...');
        subscribeFunction();
      }, 5000);
    };

    const subscribeToBulkSpeaker = async () => {
      const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
      speaker_bulk_subscription = GraphQLAPI.graphql(graphqlOperation(onCreateUpdateAgendaItemForSpeaker,
        { customer_uid: customerId, agenda_uid: votecastAgendaUid, bulk_action: VotecastBulkAction.CLERK_SPEAKER_BULK_CREATE }, authToken
      )).subscribe({
        next: async (response) => {
          const bulkUpdateResponse = response?.value?.data?.onVotecastCreateUpdateAgendaItemForSpeaker;

          if (bulkUpdateResponse?.bulk_action === VotecastBulkAction.CLERK_SPEAKER_BULK_CREATE) {
            // get the list of speakers for agenda_id
            const speakersData = await invokeGraphqlOperationWithNextToken(getVotecastSpeakersByAgendaId, { agenda_uid: votecastAgendaUid }, authParameter);

            if (speakersData?.length > 0) {

              // Find the called speaker in the speakersData array
              const calledSpeaker = speakersData.find(speaker => speaker.uid === calledSpeakerRef.current?.uid);

              // Dispatch the setSpeakers action with the updated speakersData
              dispatch(setSpeakers(speakersData));

              // If the called speaker exists in the speakersData
              if (calledSpeaker) {
                // Dispatch the setCalledSpeaker action with the calledSpeakerRef.current
                dispatch(setCalledSpeaker(calledSpeakerRef.current));

              }
            }
          }
        },
        error: (error) => handleSubscriptionError(error, subscribeToBulkSpeaker)
      });
    };

    const subscribeToSpeaker = async () => {
      const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
      speaker_subscription = GraphQLAPI.graphql(graphqlOperation(onVotecastCreateDeleteSpeaker,
        { customer_uid: customerId, agenda_uid: votecastAgendaUid }, authToken
      )).subscribe({
        next: (response) => {
          const subscribedData = response?.value?.data?.onVotecastCreateDeleteSpeaker;

          if (!subscribedData) return;
          switch (subscribedData.request_origin) {
            case VotecastRequestOrigin.ATTENDEE_CREATE:
              if (subscribedData.name !== userName) {
                const requestedSpeaker = {
                  uid: subscribedData.uid,
                  name: subscribedData.name,
                  speaker_type: subscribedData.speaker_type,
                  duration: subscribedData.duration,
                  position: subscribedData.position,
                  item_uid: subscribedData.item_uid
                };
                dispatch(setSpeakers([...speakersListRef.current, requestedSpeaker]));
              }
              break;

            case VotecastRequestOrigin.CLERK_DELETE:
            case VotecastRequestOrigin.ATTENDEE_DELETE:

              const speakerToDelete = speakersListRef.current.find(speaker => speaker.uid === subscribedData.uid);

              if (speakerToDelete) {
                dispatch(deleteSpeaker(subscribedData.uid));
              }

              if (calledSpeakerRef.current?.uid === subscribedData.uid) {
                dispatch(setCalledSpeaker(undefined));
              }
              break;

            case VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE:
            case VotecastRequestOrigin.CLERK_UPDATE:
              if ([SpeakerStatus.CALLED, SpeakerStatus.STARTED].includes(subscribedData.status)) {

                dispatch(setCalledSpeaker({
                  ...calledSpeakerRef.current,
                  ...subscribedData,
                  duration: subscribedData.duration === -1 ? 0 : subscribedData.duration,
                }));
              } else if (subscribedData.status === SpeakerStatus.STOPPED) {

                dispatch(setCalledSpeaker({
                  ...calledSpeakerRef.current,
                  ...subscribedData,
                  duration: subscribedData.duration === -1 ? 0 : subscribedData.duration,
                  start_time: subscribedData.start_time,
                  status: subscribedData.status
                }));
              }
              break;

            default:
              break;
          }
        },
        error: (error) => handleSubscriptionError(error, subscribeToSpeaker)
      });
    };

    if (isCloudVotingEnabled && votecastAgendaUid && customerId && memberJoinStatus) {
      subscribeToBulkSpeaker();
      subscribeToSpeaker();
    }
    return () => {
      if (speaker_bulk_subscription) {
        speaker_bulk_subscription.unsubscribe();
      }
      if (speaker_subscription) {
        speaker_subscription.unsubscribe();
      }
    };
  }, [isCloudVotingEnabled, customerId, votecastAgendaUid, memberJoinStatus]);
  //#endregion - #GUB-67045 subscription for legislate

  useEffect(() => {
    let motionUpdateSubscription;
    const startMotionUpdateSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        motionUpdateSubscription = await GraphQLAPI.graphql(graphqlOperation(onMotionUpdate,
          { customer_uid: customerId, agenda_uid: votecastAgendaUid }, authToken
        )).subscribe({
          next: async (response) => {
            const motionData = response.value.data.onVotecastMotionUpdate;
            if (motionData && motionData.request_origin === VotecastRequestOrigin.CLERK_CREATE) {
              dispatch(setMotionDetails({
                motionId: motionData.uid,
                motionTo: motionData.motion_action_name,
                motionItemId: motionData.parent_agenda_item_uid,
                motionMover: motionData?.motioner_name ?? "",
                motionSeconder: motionData?.seconder_name ?? "",
              }));
            } else if (motionData && liveMeeting?.motionDetails.motionId == '' && motionData.request_origin === VotecastRequestOrigin.CLERK_DELETE) {
              dispatch(setMotionDetails({
                motionTo: '',
                motionMover: '',
                motionSeconder: '',
              }));
            }
            else if(motionData && motionData.request_origin === VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE) {
              if(motionData.voting_status === VotecastVotingStatus.STOPPED) {
                dispatch(setVoteInProgressState(voteStateRef.current));
                dispatch(setVoteStop());
              } else if(motionData.voting_status === VotecastVotingStatus.STARTED) {
                if(voteInProgressStateRef.current !== undefined){
                  dispatch(setIsVotingStarted(true));
                  dispatch(setVoteResume(voteInProgressStateRef.current));
                }
              }
            }
            else {
              if (motionData && motionData.uid === motionDetailsRef.current?.motionId) {
                // If request is from clerk side and entrytime is present in the response. => Clerk has recorded/completed the motion.
                if (motionData.entrytime && motionData.request_origin === VotecastRequestOrigin.CLERK_UPDATE) {
                  dispatch(setMotionDetails({
                    motionId: '',
                    motionItemId: '',
                    motionTo: '',
                    motionMover: '',
                    motionSeconder: '',
                    previousMotion: {
                      motionTo: motionData.motion_action_name,
                      mover: motionData.motioner_name,
                      seconder: motionData.seconder_name
                    }
                  }));

                  if(!(motionData.seconder_name === 'Failed for lack of second' || motionData.seconder_name == "")) {
                    // Get all motions for active agenda item
                    const motionsForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                      { parent_uid: currentItemHypatiaId, type: AgendaItemType.Motion }, authParameter);
                    const latestRecordedMotionItem = motionsForActiveAgendaItem
                      .filter(motion => motion.entrytime)
                      .sort((a, b) => b.position - a.position)[0];

                    let latestRecordedMotionDetail = null;
                    if (latestRecordedMotionItem) {
                      const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
                      if (motionUid?.motion_id) {
                        latestRecordedMotionDetail = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
                      }
                    }
                    // console.log('latestRecordedMotionDetail on motion item record', latestRecordedMotionDetail);
                    if(latestRecordedMotionDetail === null || latestRecordedMotionDetail === undefined){
                      dispatch(setCanChairStartVote(false));
                    } else {
                      if(
                        latestRecordedMotionDetail?.voting_status === null || 
                        latestRecordedMotionDetail?.voting_status === "N/A" || 
                        latestRecordedMotionDetail?.voting_status === ""
                      ){
                        dispatch(setCanChairStartVote(true));
                      } else if(latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.COMPLETED) {
                        dispatch(setCanChairStartVote(false));
                      } 
                      // else if(
                      //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STARTED || 
                      //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STOPPED
                      // ){
                      //   dispatch(setCanChairStartVote(true));
                      //   // Get data for vote to resume and set in voteInProgressState
                      // }
                    }
                  } else {
                    dispatch(setCanChairStartVote(false));
                  }

                  if (motionData.seconder_name === 'Failed for lack of second' || motionData.seconder_name == "") {
                    toast("The Motion Failed", motionToastConfig);
                  }
                }
              }
              if (motionData && motionData.uid && !motionData.entrytime) {
                if (motionData.motioner_name != null) {
                  if (memberJoinStatus && ![userName, ""].includes(motionData.motioner_name) &&
                    motionDetailsRef.current?.motionMover === "" &&
                    [VotecastRequestOrigin.ATTENDEE_CREATE, VotecastRequestOrigin.ATTENDEE_UPDATE].includes(motionData.request_origin)) {
                    toast(`${motionData.motioner_name} has Motioned.`, motionToastConfig);
                  }
                  dispatch(setMotionDetails({ motionMover: motionData.motioner_name }));
                }
                if (motionData.seconder_name != null) {
                  if (memberJoinStatus && ![userName, ""].includes(motionData.seconder_name) &&
                    motionDetailsRef.current?.motionSeconder === "" && motionData.request_origin === VotecastRequestOrigin.ATTENDEE_UPDATE) {
                    toast(`${motionData.seconder_name} has Seconded.`, motionToastConfig);
                  }
                  dispatch(setMotionDetails({ motionSeconder: motionData.seconder_name }));
                }
                dispatch(setMotionDetails({
                  isMotionEnabled: true,
                  motionItemId: motionData.parent_agenda_item_uid,
                  motionId: motionData.uid,
                  motionTo: motionData.motion_action_name,
                  position: motionData.position
                }));
              }
            }
          },
          error: (error) => {
            console.error('Subscription: onMotionUpdate error:', error);
          }
        });
      } catch (error) {
        console.error('Error initializing subscription:', error);
      }
    }
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus) {
      startMotionUpdateSubscription();
    }
    return () => {
      if (motionUpdateSubscription) motionUpdateSubscription.unsubscribe();
    };
  }, [customerId, votecastAgendaUid, memberJoinStatus, voteStateRef, currentItemHypatiaId]);

  // Appsync graphql subscription- delete motion and Votes
  useEffect(() => {
    let motionVoteDeletesubscription;
    const startMotionVoteDeletesubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        motionVoteDeletesubscription = await GraphQLAPI.graphql(graphqlOperation(onVotecastDeleteAgendaItem, { agenda_uid: votecastAgendaUid }, authToken
        )).subscribe({
          next: async (response) => {
            const deleteAgendaItemData = response.value.data.onVotecastDeleteAgendaItem;
            if (deleteAgendaItemData) {
              if (deleteAgendaItemData.type === AgendaItemType.Motion) {
                const metadata = JSON.parse(deleteAgendaItemData?.metadata);
                if (metadata?.motion_id === motionDetailsRef.current?.motionId) {
                  dispatch(setMotionDetails({
                    motionId: '',
                    motionTo: '',
                    motionMover: '',
                    motionSeconder: '',
                  }));
                  // Also reset the related vote
                  if (voteStateRef.current && metadata?.motion_id === voteStateRef.current?.motionId) {
                    dispatch(setVoteStop());
                  }
                }
              } else if (deleteAgendaItemData.type === AgendaItemType.Vote) {
                dispatch(setIsVotingStarted(false));
                if(voteStateRef?.current && deleteAgendaItemData.uid === voteStateRef.current?.voteId){
                  dispatch(setVoteStop());
                }
                if(voteInProgressStateRef.current && deleteAgendaItemData.uid === voteInProgressStateRef.current?.voteId){
                  dispatch(setVoteInProgressStop());
                }
                // Get Deleted Vote Item Linked Motion
                const metadata = deleteAgendaItemData?.metadata ? JSON.parse(deleteAgendaItemData.metadata) : null;
                const linkedVotecastMotion = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: metadata?.motion_id }, authParameter);
                // console.log("linkedVotecastMotion", linkedVotecastMotion);
                if(linkedVotecastMotion){
                  // Mark voting_status for that motion to N/A
                  const uid = metadata?.motion_id;
                  const agenda_uid = votecastAgendaUid;
                  const customer_uid = customerId;
                  const request_origin = VotecastRequestOrigin.CLERK_UPDATE;
                  const voting_status = 'N/A';
                  dispatch(updateVotingStatus(uid, agenda_uid, customer_uid, voting_status, request_origin, VotecastAudience.LEGISLATE));
                }
              }

              if(deleteAgendaItemData?.type === AgendaItemType.Motion || deleteAgendaItemData?.type === AgendaItemType.Vote){
                // Get all motions for active agenda item
                const motionsForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                  { parent_uid: currentItemHypatiaId, type: AgendaItemType.Motion }, authParameter);
                const latestRecordedMotionItem = motionsForActiveAgendaItem
                  .filter(motion => motion.entrytime)
                  .sort((a, b) => b.position - a.position)[0];

                let latestRecordedMotionDetail = null;
                if (latestRecordedMotionItem) {
                  const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
                  if (motionUid?.motion_id) {
                    latestRecordedMotionDetail = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
                  }
                }
                // If Motion Deleted
                if(deleteAgendaItemData?.type === AgendaItemType.Motion){
                  dispatch(setMotionDetails(
                    {
                      previousMotion: latestRecordedMotionDetail !== null ? {
                        motionTo: latestRecordedMotionDetail?.motion_action_name,
                        mover: latestRecordedMotionDetail?.motioner_name,
                        seconder: latestRecordedMotionDetail?.seconder_name
                      } : undefined
                    }
                  ));
                }
                // console.log('latestRecordedMotionDetail on delete vote or motion item', latestRecordedMotionDetail);
                if(latestRecordedMotionDetail === null || latestRecordedMotionDetail === undefined){
                  dispatch(setCanChairStartVote(false));
                } else {
                  if(!(latestRecordedMotionDetail.seconder_name === 'Failed for lack of second' || latestRecordedMotionDetail.seconder_name == "")) {
                    if(
                      latestRecordedMotionDetail?.voting_status === null || 
                      latestRecordedMotionDetail?.voting_status === "N/A" || 
                      latestRecordedMotionDetail?.voting_status === ""
                    ){
                      dispatch(setCanChairStartVote(true));
                    } else if(latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.COMPLETED) {
                      dispatch(setCanChairStartVote(false));
                    } 
                    // else if(
                    //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STARTED || 
                    //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STOPPED
                    // ){
                    //   dispatch(setCanChairStartVote(true));
                    //   // Get data for vote to resume and set in voteInProgressState
                    // }
                  } else {
                    dispatch(setCanChairStartVote(false));
                  }
                }
              }
            }
          },
          error: (error) => {
            console.error('Subscription: onVotecastDeleteAgendaItem error:', error);
          }
        });
      } catch (error) {
        console.error('Error initializing subscription:', error);
      }
    };
    if (isCloudVotingEnabled && votecastAgendaUid) {
      startMotionVoteDeletesubscription();
    }
    return () => {
      if (motionVoteDeletesubscription) motionVoteDeletesubscription.unsubscribe();
    };
  }, [votecastAgendaUid, customerId, currentItemHypatiaId]);

  useEffect(() => {
    let vote_subscription;
    const startSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        vote_subscription = await GraphQLAPI.graphql(graphqlOperation(onCreateUpdateAgendaItemForVote,
          { customer_uid: customerId, agenda_uid: votecastAgendaUid, type: AgendaItemType.Vote }, authToken
        )).subscribe({
          next: async (response) => {
            const voteAgendaItemData = response.value.data.onVotecastCreateUpdateAgendaItemForVote;
            if (voteAgendaItemData) {
              if ((voteStateRef?.current || voteInProgressStateRef?.current) && (voteAgendaItemData.uid === voteStateRef?.current?.voteId || voteAgendaItemData.uid === voteInProgressStateRef?.current?.voteId)) {
                if (voteAgendaItemData.bulk_action === VotecastBulkAction.CLERK_MARK_VOTES_ALL_YES) {
                  if(voteStateRef.current !== null){
                    dispatch(setVotesChanged(
                      voteStateRef.current?.votes.map(vote => ({
                        ...vote,
                        voting_configuration_name: VoteActionEnum.Yes
                      }))
                    ));
                  } else if (voteInProgressStateRef.current !== null) {
                    dispatch(setVotesInProgressChanged(
                      voteInProgressStateRef.current?.votes.map(vote => ({
                        ...vote,
                        voting_configuration_name: VoteActionEnum.Yes
                      }))
                    ));
                  }
                  const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid }, authParameter);
                  if (ballotsData && ballotsData.length > 0) {
                    if(voteStateRef.current !== null){
                      dispatch(setVotesChanged(
                        ballotsData.map(vote => ({
                          ...vote,
                          voting_configuration_name: VoteActionEnum.Yes
                        }))
                      ));
                      const currentUserVote = ballotsData.find(vote => vote.member_uid === liveMeeting.memberDetails.memberId);
                      if(currentUserVote){
                        dispatch(setCurrentVote({ ...currentUserVote, voting_configuration_name: VoteActionEnum.Yes }));
                      }   
                    } else if (voteInProgressStateRef.current !== null) {
                      dispatch(setVotesInProgressChanged(
                        ballotsData.map(vote => ({
                          ...vote,
                          voting_configuration_name: VoteActionEnum.Yes
                        }))
                      ));
                      const currentUserVote = ballotsData.find(vote => vote.member_uid === liveMeeting.memberDetails.memberId);
                      if(currentUserVote){
                        dispatch(setCurrentVoteInProgress({ ...currentUserVote, voting_configuration_name: VoteActionEnum.Yes }));
                      }   
                    }                 
                  }
                }
                else if ([VotecastBulkAction.ATTENDEE_CHAIR_RESET_VOTES, VotecastBulkAction.CLERK_RESET_VOTES].includes(voteAgendaItemData.bulk_action)) {
                  if(voteStateRef.current !== null){
                    dispatch(setVotesChanged(
                      voteStateRef.current?.votes.map(vote => ({
                        ...vote,
                        voting_configuration_name: ''
                      }))
                    ));
                    const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid }, authParameter);
                    if (ballotsData && ballotsData.length > 0) {
                      dispatch(setVotesChanged(
                        ballotsData.map(vote => ({
                          ...vote,
                          voting_configuration_name: ''
                        }))
                      ));
                      const currentUserVote = ballotsData.find(vote => vote.member_uid === liveMeeting.memberDetails.memberId);
                      if(currentUserVote){
                        dispatch(setCurrentVote({ ...currentUserVote, voting_configuration_name: '' }));
                      }                    
                    }
                  } else if (voteInProgressStateRef.current !== null) {
                    dispatch(setVotesInProgressChanged(
                      voteInProgressStateRef.current?.votes.map(vote => ({
                        ...vote,
                        voting_configuration_name: ''
                      }))
                    ));
                    const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid }, authParameter);
                    if (ballotsData && ballotsData.length > 0) {
                      dispatch(setVotesInProgressChanged(
                        ballotsData.map(vote => ({
                          ...vote,
                          voting_configuration_name: ''
                        }))
                      ));
                      const currentUserVote = ballotsData.find(vote => vote.member_uid === liveMeeting.memberDetails.memberId);
                      if(currentUserVote){
                        dispatch(setCurrentVoteInProgress({ ...currentUserVote, voting_configuration_name: '' }));
                      }                    
                    }
                  }
                }
                else if (voteAgendaItemData.entrytime) {
                  dispatch(setIsVotingStarted(false));
                  dispatch(setVoteStop());
                  dispatch(setVoteInProgressStop());
                  //To show voting result modal
                  const motionUid = JSON.parse(voteAgendaItemData.metadata);
                  // Mark voting_status for that motion to COMPLETED
                  const uid = motionUid?.motion_id;
                  const agenda_uid = liveMeeting.votecastAgendaUid;
                  const customer_uid = customerId;
                  const request_origin = VotecastRequestOrigin.CLERK_UPDATE;
                  const voting_status = VotecastVotingStatus.COMPLETED;
                  dispatch(updateVotingStatus(uid, agenda_uid, customer_uid, voting_status, request_origin, VotecastAudience.LEGISLATE));
                  if (motionUid?.motion_id) {
                    const motionData = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid?.motion_id }, authParameter);
                    if (motionData && (motionData.motion_result_name === 'Passed' || motionData.motion_result_name === 'Failed')) {
                      setVotingResult(motionData.motion_result_name);
                      setShowVotingResult(true);
                    }
                  }
                  // Get all motions for active agenda item
                  const motionsForActiveAgendaItem = await invokeGraphqlOperation(getVotecastAgendaItemsByParentUid,
                    { parent_uid: currentItemHypatiaId, type: AgendaItemType.Motion }, authParameter);
                  const latestRecordedMotionItem = motionsForActiveAgendaItem
                    .filter(motion => motion.entrytime)
                    .sort((a, b) => b.position - a.position)[0];

                  let latestRecordedMotionDetail = null;
                  if (latestRecordedMotionItem) {
                    const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
                    if (motionUid?.motion_id) {
                      latestRecordedMotionDetail = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authParameter);
                    }
                  }
                  // console.log('latestRecordedMotionDetail on vote item record', latestRecordedMotionDetail);
                  if(latestRecordedMotionDetail === null || latestRecordedMotionDetail === undefined){
                    dispatch(setCanChairStartVote(false));
                  } else {
                    if(!(latestRecordedMotionDetail.seconder_name === 'Failed for lack of second' || latestRecordedMotionDetail.seconder_name == "")) {
                      if(
                        latestRecordedMotionDetail?.voting_status === null || 
                        latestRecordedMotionDetail?.voting_status === "N/A" || 
                        latestRecordedMotionDetail?.voting_status === ""
                      ){
                        dispatch(setCanChairStartVote(true));
                      } else if(latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.COMPLETED) {
                        dispatch(setCanChairStartVote(false));
                      } 
                      // else if(
                      //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STARTED || 
                      //   latestRecordedMotionDetail?.voting_status === VotecastVotingStatus.STOPPED
                      // ){
                      //   dispatch(setCanChairStartVote(true));
                      //   // Get data for vote to resume and set in voteInProgressState
                      // }
                    } else {
                      dispatch(setCanChairStartVote(false));
                    }
                  }
                }
              }
              else if (voteAgendaItemData.bulk_action === VotecastBulkAction.CLERK_VOTE_BULK_CREATE) {
                const activeAgendaItemData = await invokeGraphqlOperation(getActiveAgendaItem, { agenda_uid: votecastAgendaUid }, authParameter);
                const voteItemMetadata = JSON.parse(voteAgendaItemData?.metadata ?? "{}");
                const motionData = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: voteItemMetadata?.motion_id }, authParameter);
                if (motionData) {
                  const metadata = JSON.parse(activeAgendaItemData?.metadata ?? "{}");
                  dispatch(setVoteStarted({
                    itemText: activeAgendaItemData?.title,
                    voteId: voteAgendaItemData.uid,
                    motionId: motionData.uid,
                    motionText: motionData?.text ?? "",
                    motionAction: metadata?.suggested_action ?? "",
                    motionTo: motionData?.motion_action_name ?? "",
                    motionMover: motionData?.motioner_name ?? "",
                    motionSeconder: motionData?.seconder_name ?? "",
                  }));
                  dispatch(setIsVotingStarted(true));
                }

                const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid }, authParameter);
                if (ballotsData && ballotsData.length > 0) {
                  const userVote = ballotsData.find(vote => vote.member_uid === liveMeeting.memberDetails.memberId);
                  dispatch(setVoteStarted({ ballotId: userVote?.uid }));
                  dispatch(setVotesChanged(ballotsData));
                }
              }
            }
          },
          error: (error) => {
            console.error('Subscription: onCreateUpdateAgendaItemForVote error:', error);
          }
        });
      } catch (error) {
        console.error('Error initializing subscription:', error);
      }
    };
    if (customerId && votecastAgendaUid && liveMeeting.memberDetails.memberId && memberJoinStatus) {
      startSubscription();
    }
    return () => {
      if (vote_subscription) vote_subscription.unsubscribe();
    };
  }, [customerId, votecastAgendaUid, liveMeeting.memberDetails.memberId, memberJoinStatus, currentItemHypatiaId]);

  useEffect(() => {
    if (itemUid) {
      dispatch(setSelectedItemKey(itemUid));
    }
  }, [dispatch, itemUid]);

  useEffect(() => { }, [agenda?.name]);

  useEffect(() => {
    // Will fetch the real userGuid from graphql
    // dispatch(setPersonUid("b1b5fc14-a58a-40b0-a93f-5010d9e7874c")); // CV TEMP TO BE CHANGED

    // returned function will be called on component unmount
    return () => {
      dispatch(resetData());
    };
  }, []);

  const toastConfig = {
    className: "live-meeting-toast",
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    closeButton: false,
    onClose: () => window.location.reload(), // Redirect on toast close
    style: { width: '475px' }
  };

  const getToastConfig = (overrides = {}) => ({
    ...toastConfig,
    ...overrides
  });

  const motionToastConfig = {
    ...toastConfig,
    onClose: undefined,
    style: undefined
  };

  useEffect(() => {
    let stopMeeting_subscription;
    const startStopMeetingSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        stopMeeting_subscription = GraphQLAPI.graphql(graphqlOperation(onMeetingEndUpdate, { uid: votecastAgendaUid }, authToken
        )).subscribe({
          next: (response) => {
            const votecastMeetingData = response.value.data.onVotecastStopMeeting;
            if (votecastMeetingData && votecastMeetingData.uid === votecastAgendaUid && memberJoinStatus) {
              toast("Clerk has ended the meeting. The agenda is no longer live.", toastConfig);
            }
          },
          error: (error) => {
            console.error('Subscription onMeetingEndUpdate error:', error);
          }
        });
      } catch (error) {
        console.error('Error initializing subscription:', error);
      }
    };
    if (isCloudVotingEnabled && liveMeeting.votecastAgendaUid && memberJoinStatus) {
      startStopMeetingSubscription();
    }
    return () => {
      if (stopMeeting_subscription) stopMeeting_subscription.unsubscribe();
    };
  }, [isCloudVotingEnabled, memberJoinStatus, votecastAgendaUid]);

  useEffect(() => {
    let deleteAttendee_subscription;
    const startDeleteAttendeeSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        deleteAttendee_subscription = GraphQLAPI.graphql(
          graphqlOperation(onVotecastDeleteAttendee, { uid: liveMeeting.memberDetails.memberId, customer_uid: customerId }, authToken
          )).subscribe({
            next: (response) => {
              const votecastDeletedMemberData = response.value.data.onVotecastDeleteMember;
              if (votecastDeletedMemberData && votecastDeletedMemberData.uid === liveMeeting.memberDetails.memberId) {
                toast("You have been removed from the meeting. This agenda is no longer live for you.", toastConfig);
              }
            },
            error: (error) => {
              console.error('Subscription error:', error);
            }
          });
      } catch (error) {
        console.error('Error initializing subscription:', error);
      }
    }
    if (isCloudVotingEnabled && customerId && liveMeeting.memberDetails.memberId && memberJoinStatus) {
      startDeleteAttendeeSubscription();
    }
    return () => {
      if (deleteAttendee_subscription) deleteAttendee_subscription.unsubscribe();
    };
  }, [isCloudVotingEnabled, memberJoinStatus, liveMeeting.memberDetails.memberId]);

  useEffect(() => {
    let memberUpdateSubscription;
    const startMemberUpdateSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        memberUpdateSubscription = GraphQLAPI.graphql(
          graphqlOperation(onVotecastMemberUpdate, { uid: liveMeeting.memberDetails.memberId, customer_uid: customerId, agenda_uid: votecastAgendaUid }, authToken
          )).subscribe({
            next: (response) => {
              const responseMemberDetails = response?.value?.data?.onVotecastMemberUpdate;
              const isAttendeePresent = responseMemberDetails?.status?.toLowerCase() === MemberAttendance.PRESENT ? true : false;
              if (isCloudVotingEnabled && currentUserRef.current?.memberJoinStatus && currentUserRef.current?.isChair && !responseMemberDetails?.chair && (currentUserRef.current?.isAttendeePresent == isAttendeePresent)) {
                toast(
                  "The chair for this meeting has been reset. Your permissions have been updated.",
                  getToastConfig({
                    style: { width: '630px' },
                    onClose: undefined
                  })
                );
              }
              dispatch(setMemberDetails({
                isAttendeePresent: (responseMemberDetails?.status && responseMemberDetails?.status?.toLowerCase() === MemberAttendance.PRESENT),
                isVoter: responseMemberDetails?.voting,
                isChair: responseMemberDetails?.chair
              }));
            },
            error: (error) => {
              console.error('memberUpdateSubscription error:', error);
            }
          });
      } catch (error) {
        console.error('Error initializing subscription:', error);
      }
    };
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberId) {
      startMemberUpdateSubscription();
    }
    return () => {
      if (memberUpdateSubscription) memberUpdateSubscription.unsubscribe();
    }
  }, [customerId, votecastAgendaUid, liveMeeting.memberDetails]);

  useEffect(() => {
    let vote_subscription;
    const startVoteSubscription = async () => {
      try {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        vote_subscription = GraphQLAPI.graphql(
          graphqlOperation(onBallotUpdate, { customer_uid: customerId, agenda_uid: votecastAgendaUid }, authToken
          )).subscribe({
            next: (response) => {
              const updateVoteData = response.value.data.onVotecastUpdateBallot;
              if (updateVoteData && (updateVoteData.parent_agenda_item_uid === voteStateRef.current?.voteId || updateVoteData.parent_agenda_item_uid === voteInProgressStateRef.current?.voteId)) {
                const voter = {
                  uid: updateVoteData.uid,
                  motion_uid: updateVoteData.motion_uid,
                  member_name: updateVoteData.member_name,
                  member_uid: updateVoteData.member_uid,
                  voting_configuration_name: updateVoteData.voting_configuration_name
                };
                if(voteStateRef.current !== null){
                  dispatch(setCurrentVote(voter));
                } else if(voteInProgressStateRef.current !== null){
                  dispatch(setCurrentVoteInProgress(voter));
                }
              }
            },
            error: (error) => {
              console.error('Subscription onBallotUpdate error:', error);
            }
          });
      } catch (error) {
        console.error('Error initializing subscription:', error);
      }
    }
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberJoinStatus) {
      startVoteSubscription();
    }
    return () => {
      if (vote_subscription) vote_subscription.unsubscribe();
    };
  }, [customerId, liveMeeting.votecastAgendaUid, memberJoinStatus]);

  const Page = ({ children }) => {
    return (
      <>
        {(isCloudVotingEnabled && votecast_enableErrorNotification) ? <ErrorNotification /> : ""}
        <div className={votecast_enableErrorNotification ? 'agenda-error-Notification' : ''}>
          <Breadcrumb />
        </div>
        {children}
      </>
    );
  };

  const renderTitle = () => {
    const dateInOffsetTime = getDateInOffsetTime(agenda.starttime);
    const title = `${agenda.name}, ${dateInOffsetTime.format(
      settings.monthAndTimeDisplayFormat
    )}`;
    return (
      <h2 className="agenda-title">
        {title}
        {isCloudVotingEnabled && isMeetingRunning && (
          <img className="padding-left-2" src={CvMeetingLiveIcon} alt='Live Meeting' id='agenda-live-meeting' />
        )}
      </h2>
    );
  };

  const renderVotingResultModal = () => {
    return (
      <div>

        {/* Voting Result Modal */}
        <VotingResultModal
          isOpen={showVotingResult}
          onClose={handleClose}
          votingResult={votingResult}
        />
      </div>
    );
  }

  const renderAgendaPacketButton = () => {
    if (agenda.council_packet_status_id == 2 || agenda.agenda_packet_status_id == 2) {
      const packetUrl = validatePacketUrl(agenda);
      if (packetUrl) {
        return (
          <a className={classNames("agenda-packet-link", isCloudVotingEnabled ? "agenda-packet-link-cloud-voting" : "")}
            href={packetUrl} target="_blank" aria-label="Agenda Packet Link" rel="noreferrer">
            <Button unstyled={isCloudVotingEnabled} className="agenda-packet-button" size="sm">
              <FontAwesomeIcon
                icon={faFilePdf}
                style={{ marginRight: 4 }}
              />
              Agenda Packet
            </Button>
          </a>
        );
      } else {
        return null;
      }
    }
  }

  const updateMemberJoinedInput = {
    customer_uid: customerId,
    uid: memberId,
    availability_status: AvailabilityStatus.JOINED
  }

  const updateMemberLeftInput = {
    customer_uid: customerId,
    uid: memberId,
    availability_status: AvailabilityStatus.LEFT
  }

  const handleJoinMeetingClick = async () => {
    const meetingData = await invokeGraphqlOperation(getAgendaListByMeetingGuid, { customer_uid: customerId, meeting_guid: agendaUid }, authParameter);

    const filteredAndSortedMeetingData = meetingData
      ?.filter(item => [MeetingStatus.OPEN, MeetingStatus.RUNNING, MeetingStatus.PAUSED].includes(item.meeting_status))
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const votecastAgendaUid = filteredAndSortedMeetingData?.[0]?.uid ?? null;
    dispatch(setLiveMeeting({ meetingGuid: agendaUid, votecastAgendaUid: votecastAgendaUid }));

    if (filteredAndSortedMeetingData?.length > 0) {
      const updateMemberData = await invokeGraphqlOperation(updateMemberAvailabilityStatus, { input: updateMemberJoinedInput }, authParameter);

      if (updateMemberData && updateMemberData.availability_status === AvailabilityStatus.JOINED) {
        dispatch(setMemberDetails({
          memberId: updateMemberData.uid,
          memberJoinStatus: updateMemberData.availability_status === AvailabilityStatus.JOINED,
          isAttendeePresent: updateMemberData.status && updateMemberData.status.toLowerCase() === MemberAttendance.PRESENT,
          isChair: updateMemberData.chair,
          isVoter: updateMemberData.voting,
        }));
      }
    }
    else {
      // show error notification banner
      setIsMeetingOpenOrRunning(false);
      setIsMeetingRunning(false);
      dispatch(setEnableActiveAgendaErrorNotification(true));
    }
  }

  const handleLeaveMeetingClick = async () => {
    const updateMemberData = await invokeGraphqlOperation(updateMemberAvailabilityStatus, { input: updateMemberLeftInput }, authParameter);

    if (updateMemberData && updateMemberData.availability_status === AvailabilityStatus.LEFT) {
      testOnLeaveMeeting(); //todo: test
      dispatch(setMemberDetails({
        memberId: updateMemberData.uid,
        memberJoinStatus: false,
      }));
      dispatch(setCalledSpeaker(undefined));
    }
  }

  const renderActiveAgenda = () => {
    return (
      <Page>
        <div className="agenda-title-container">
          <div className="agenda-title-side-container"></div>
          <div>{renderTitle()}</div>
          <div className="agenda-title-side-container">
            {isCloudVotingEnabled && (
              isMeetingOpenOrRunning && liveMeeting.memberDetails?.memberId ? (
                memberJoinStatus ? <LeaveMeetingButton onClick={handleLeaveMeetingClick} /> : <JoinMeetingButton onClick={handleJoinMeetingClick} />
              ) : (
                <JoinMeetingButton onClick={handleJoinMeetingClick} disabled={true} />
              )
            )}
            {!isCloudVotingEnabled && renderAgendaPacketButton()}
          </div>
        </div>
        <div id="agenda-content-container" className="agenda-content-container">
          {isCloudVotingEnabled && liveMeeting.isVotingStarted && <VotingModal />}
          {renderVotingResultModal()}
          {isCloudVotingEnabled && renderAgendaPacketButton()}
          <CardGroup className="active-agenda-content">
            <Card gridLayout={{ desktop: { col: 6 } }}>
              <AgendaItems itemsTreeData={treeData} />
            </Card>
            <Card gridLayout={{ desktop: { col: 6 } }} id="agenda-item-detail">
              <Container />
            </Card>
          </CardGroup>
        </div>
      </Page>
    );
  };

  if (isAgendaError || isNotesError || isBookmarksError) {
    return (
      <Page>
        <p>We encountered a problem while loading this agenda</p>
      </Page>
    );
  }

  if (isCurrentUserError /* || isCurrentPersonUidError */ && isCloudVotingEnabled) {
    return (
      <Page>
        <p>Error loading User data. Please try again later.</p>
      </Page>
    );
  }

  if (isLoading) {
    return (
      <Page>
        <div
          className="display-flex flex-column flex-align-center flex-justify-center text-blue"
          style={{ minHeight: 200 }}
        >
          <FontAwesomeIcon
            icon={"spinner"}
            size={"2x"}
            className="fa-spin"
            style={{ marginBottom: 8 }}
          />
          Loading...
        </div>
      </Page>
    );
  } else {
    return renderActiveAgenda();
  }
};

ActiveAgenda.displayName = "Agenda";


export default ActiveAgenda;
