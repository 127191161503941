import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLiveMeeting, selectMotionState,setIsVotingStarted,
  selectIsVotingStarted, setMotionDetails,
  selectCanChairStartVote } from '../../common/liveMeetingSlice';
import { Button } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Popover } from 'antd';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from "uuid";
import { createVotecastMotion, updateVotecastMotion } from '../../../../amplify/graphql/mutations';
import { VotecastAudience, VotecastRequestOrigin, AgendaItemType, VotecastVotingStatus } from '../../../constants/cloudVotingConstants';
import { invokeGraphqlOperation, getJWTToken } from '../../../helpers/votecastWebHelpers/graphqlOperations';
import {
  getVotecastMotionById, 
  getVotecastAgendaItemsByParentUid, 
  getVotecastMembersByAgendaUid 
} from '../../../../amplify/graphql/queries';
import { selectIsCloudVotingEnabled } from "../../common/settingSlice";
// import { graphqlOperation } from 'aws-amplify';
import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import { updateVotingStatus } from '../../../components/new_limaweb/actions/voteActions';

const toastConfig = {
  className: "live-meeting-toast",
  position: "bottom-left",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  closeButton: false
};

export default function Motion() {
  const dispatch = useDispatch();

  const motionState = useSelector(selectMotionState);
  const liveMeeting = useSelector(selectLiveMeeting);
  const votecastAgendaUid = liveMeeting.votecastAgendaUid;
  const customerId = useSelector(state => state.auth.customer_uid);
  const votecast_isVotingStarted = useSelector(selectIsVotingStarted);
  const authParameter = { customerId: customerId, audience: VotecastAudience.LEGISLATE };
  const canChairStartVote = useSelector(selectCanChairStartVote);
  const currentItemHypatiaId = liveMeeting.currentItemHypatiaId;
  const voteInProgressState = liveMeeting.voteInProgressState;

  const createOrUpdateMotionInput = {
    uid: !motionState.motionId ? uuidv4() : motionState.motionId,
    customer_uid: customerId,
    agenda_uid: votecastAgendaUid,
    origin_uid: '',
    position: !motionState.position ? 1 : (motionState.position + 1),
    motion_type_name: '',
    motion_result_name: '',
    parent_agenda_item_uid: liveMeeting.motionDetails?.motionItemId ? liveMeeting.motionDetails?.motionItemId : liveMeeting.currentItemHypatiaId,
    motion_passage_rule_name: '',
    motion_action_name: liveMeeting.motionDetails.motionTo ? liveMeeting.motionDetails.motionTo : '',
    request_origin: VotecastRequestOrigin.ATTENDEE_UPDATE
  };

  const setMover = async () => {
    const setMoverInput = {
      ...createOrUpdateMotionInput,
      motioner_uid: liveMeeting?.memberDetails.memberId,
      motioner_name: motionState.username
    }

    let moverData = {};
    if (motionState.motionId) {
      moverData = await invokeGraphqlOperation(updateVotecastMotion, { input: setMoverInput }, authParameter);
    } else {
      moverData = await invokeGraphqlOperation(createVotecastMotion, 
        { input: {...setMoverInput, request_origin: VotecastRequestOrigin.ATTENDEE_CREATE} }, authParameter);
    }

    if (moverData && moverData.uid && moverData.motioner_name) {
      toast("You have Motioned", toastConfig);
    }
  }

  const setSeconder = async () => {
    const setSeconderInput = {
      ...createOrUpdateMotionInput,
      seconder_uid: liveMeeting?.memberDetails.memberId,
      seconder_name: motionState.username
    }
    const seconderData = await invokeGraphqlOperation(updateVotecastMotion, { input: setSeconderInput }, authParameter);
    if (seconderData && seconderData.seconder_name) {
      toast("You have Seconded", toastConfig);
    }
  }

  const withdrawMover = async () => {
    const withdrawMoverInput = {
      ...createOrUpdateMotionInput,
      motioner_uid: '',
      motioner_name: '',
      seconder_uid: '',
      seconder_name: ''
    }
    await invokeGraphqlOperation(updateVotecastMotion, { input: withdrawMoverInput }, authParameter);
  }

  const withdrawSeconder = async () => {
    const withdrawSeconderInput = {
      ...createOrUpdateMotionInput,
      seconder_uid: '',
      seconder_name: ''
    }
    await invokeGraphqlOperation(updateVotecastMotion, { input: withdrawSeconderInput }, authParameter);
  }

  const resetMotion = () => {
    withdrawMover();
    dispatch(setMotionDetails({
      isMotionEnabled: false,
      motionTo: '',
      motionMover: '',
      motionSeconder: ''
    }));
  }

  const callVotingModal = () => {
    dispatch(setIsVotingStarted(true));
  }

  const createVoteMinutesItemFromChair = async () => {
    // Query for voters list
    try {
      if(voteInProgressState === undefined || voteInProgressState === null) {
        const authToken = await getJWTToken(customerId, VotecastAudience.LEGISLATE);
        const allAttendeesResponse = await GraphQLAPI.graphql(graphqlOperation(getVotecastMembersByAgendaUid, { agenda_uid: votecastAgendaUid, customer_uid: customerId }, authToken));
        let allVoters = allAttendeesResponse?.data?.getVotecastMembersByAgendaUid?.filter(member => member.voting && member.status === "present") || [];
        
        if (allVoters.length > 0) {
          allVoters = allVoters.map(voter => ({
            ...voter,
            agenda_id: voter.agenda_uid,
            agenda_uid: undefined // Remove the original agenda_uid key
          }));

          //Find Motion Details For Voting
          const motionsForActiveAgendaItemResponse = await GraphQLAPI.graphql(graphqlOperation(getVotecastAgendaItemsByParentUid, { parent_uid: currentItemHypatiaId, type: AgendaItemType.Motion }, authToken));
          const motionsForActiveAgendaItem = motionsForActiveAgendaItemResponse?.data?.getVotecastAgendaItemsByParentUid ?? [];
          const latestRecordedMotionItem = motionsForActiveAgendaItem
          .filter(motion => motion.entrytime)
          .sort((a, b) => b.position - a.position)[0];
          let latestRecordedMotionDetail = null;
          if (latestRecordedMotionItem) {
            const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
            if (motionUid?.motion_id) {
              const motionDetailResponse = await GraphQLAPI.graphql(graphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }, authToken));
              latestRecordedMotionDetail = motionDetailResponse?.data?.getVotecastMotionById;
            }
          }

          const authParameter = { customerId: customerId, audience: VotecastAudience.LEGISLATE };
          if (latestRecordedMotionDetail) {
            // if(!latestRecordedMotionDetail.voting_status || latestRecordedMotionDetail.voting_status === VotecastVotingStatus.STOPPED){
              // Update Votecast Motions voting_status & request Origin
              if(!(latestRecordedMotionDetail.seconder_name === 'Failed for lack of second' || latestRecordedMotionDetail.seconder_name == "")) {
                const uid = latestRecordedMotionDetail?.uid;
                const agenda_uid = votecastAgendaUid;
                const customer_uid = customerId;
                const request_origin = VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE;
                const voting_status = VotecastVotingStatus.STARTED;
                dispatch(updateVotingStatus(uid, agenda_uid, customer_uid, voting_status, request_origin, VotecastAudience.LEGISLATE));
              } else {
                console.log('Cannot START vote for a failed motion');return false;
              }              
            // }
          }
        }
      } else {
        // set voting_status to STARTED in motions table
        const uid = voteInProgressState?.motionId;
        const agenda_uid = votecastAgendaUid;
        const customer_uid = customerId;
        const request_origin = VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE;
        const voting_status = VotecastVotingStatus.STARTED;
        dispatch(updateVotingStatus(uid, agenda_uid, customer_uid, voting_status, request_origin, VotecastAudience.LEGISLATE));
      }
    } catch (error) {
      console.error('Error fetching voters:', error);
    }
  }

  const motionTo = (
    <div className="display-flex margin-top-2">
      <div className="flex-1">
        <div className="sub-title">Motion To</div>
        <div id="motion-to" className='sub-text'>
          {motionState.motionTo
            ? motionState.motionTo
            : 'Describing Motion'}
        </div>
      </div>

    </div>
  );

  const messageLabel = (message) => (
    <div className='motion-message'>
      <FontAwesomeIcon icon={['far', 'check-circle']} color='#4CAF50' className='margin-right-1' />
      <span>{message}</span>
    </div>
  );

  const motionButton = (
    <Button type="button" disabled={!motionState.userCanMotion} size='small' className="motion-button padding-y-1"
      onClick={setMover} title="Click to motion"
    >
      Motion
    </Button>
  );

  const withdrawMotionButton = (
    <Button type="button" size='small' className="withdraw-motion-button" unstyled
      onClick={withdrawMover} title="Click to withdraw motion"
    >
      <FontAwesomeIcon icon={'times'} size={'lg'} className="margin-right-1" />
      <span>Withdraw Motion</span>
    </Button>
  );

  const mover = (
    <div className={classNames("motion-mover-container display-flex margin-top-2", { "motion-submitted": motionState.isUserSubmittedMotion })}>
      <div className="flex-1">
        <div className="sub-title">Mover</div>
        <div id="motion-mover" className='sub-text'>
          {motionState.mover
            ? (motionState.isUserSubmittedMotion ? messageLabel("You have Motioned") : motionState.mover)
            : 'Name of Mover'}
        </div>
      </div>
      <div className="flex-auto">
        {motionState.isUserSubmittedMotion ? withdrawMotionButton : motionButton}
      </div>
    </div>
  );

  const seconderButton = (
    <Button type="button" disabled={!motionState.userCanSecond} size='small' className="motion-button padding-y-1"
      onClick={setSeconder} title="Click to second"
    >
      Second Motion
    </Button>
  );

  const withdrawSeconderButton = (
    <Button type="button" size='small' className="withdraw-motion-button" unstyled
      onClick={withdrawSeconder} title="Click to withdraw seconder"
    >
      <FontAwesomeIcon icon={'times'} size={'lg'} className="margin-right-1" />
      <span>Withdraw Second</span>
    </Button>
  );

  const seconder = (
    <div className={classNames("motion-mover-container display-flex margin-top-2", { "motion-submitted": motionState.isUserSubmittedSecond })}>
      <div className="flex-1">
        <div className="sub-title">Seconder</div>
        <div id="motion-seconder" className='sub-text'>
          {motionState.seconder
            ? (motionState.isUserSubmittedSecond ? messageLabel("You have Seconded") : motionState.seconder)
            : 'Name of Seconder'}
        </div>
      </div>
      <div className="flex-auto">
        {motionState.isUserSubmittedSecond ? withdrawSeconderButton : seconderButton}
      </div>
    </div>
  );

  const previousMotionTitle = (
    <span className='previous-motion-title'>
      Previous Motion Summary
    </span>
  );

  const previousMotion = () => {
    const isEmpty = !motionState.previousMotion;
    if (isEmpty) {
      return (
        <div>
          <div className='previous-motion-sub-title'>No summary available</div>
          <div className='previous-motion-sub-text'>A summary will appear once a <br /> motion is complete</div>
        </div>
      )
    }

    const { motionTo, mover, seconder } = motionState.previousMotion;
    if (seconder === 'Failed for lack of second' || seconder == "") {
      return (
        <div>
          <div className='previous-motion-sub-title'>Motion Failed</div>
          <div className='previous-motion-sub-text'>Motion failed due to lack of seconder.</div>
        </div>
      )
    }
    return (
      <div className='padding-1'>
        <div className='display-flex flex-column'>
          <div className='previous-motion-sub-title'>Motion to</div>
          <div className='previous-motion-sub-text'>{motionTo ?? "Describing Motion"}</div>
        </div>
        <div className='display-flex flex-column margin-top-2'>
          <div className='previous-motion-sub-title'>Mover</div>
          <div className='previous-motion-sub-text'>{mover ?? "Name of Mover"}</div>
        </div>
        <div className='display-flex flex-column margin-top-2'>
          <div className='previous-motion-sub-title'>Seconder</div>
          <div className='previous-motion-sub-text'>{seconder ?? "Name of Seconder"}</div>
        </div>
      </div>
    )

  };

  return (
    <div className="motion-container padding-0 margin-top-2 margin-bottom-2">
      <div className="display-flex">
        <div className="flex-1">
          <span className="text-base-darkest text-bold font-sans-xs">Motion on the Floor</span>
        </div>

        <div className="flex-auto">
          <Popover
            content={previousMotion()}
            title={previousMotionTitle} prefixCls="legislate-popover"
            placement='bottomRight'>
            <span style={{ color: "#0058AA", fontSize: "14px" }}>Motion Summary</span>
          </Popover>
        </div>
      </div>
      {motionTo}
      {mover}
      {seconder}
      {liveMeeting.memberDetails?.isAttendeePresent && liveMeeting.memberDetails?.isChair && (
        <div className="buttons-container">
          <Button
            type="button"
            className={'reset-motion-text'}
            unstyled
            onClick={resetMotion}
            disabled={!liveMeeting.motionDetails.isMotionEnabled || !motionState.mover || votecast_isVotingStarted}
            style={{
              color: !liveMeeting.motionDetails.isMotionEnabled || !motionState.mover || votecast_isVotingStarted ? '#B3B7BA' : undefined,
              background: !liveMeeting.motionDetails.isMotionEnabled || !motionState.mover || votecast_isVotingStarted ? 'white' : undefined
            }}
          >
            <FontAwesomeIcon
              icon={'redo'}
              size={'lg'}
              style={{ marginRight: 6 }}
              className='reset-motion-text-icon'

            />
            <span>Reset Motion</span>
          </Button>

          <Button
            type="button"
            className={'start-voting-text'}
            unstyled
            onClick={createVoteMinutesItemFromChair}
            disabled={!canChairStartVote}
            style={{
              color: !canChairStartVote ? '#B3B7BA' : undefined,
              background: !canChairStartVote ? 'white' : undefined
            }}
          >
            <FontAwesomeIcon
              icon={'vote-yea'}
              size={'lg'}
              style={{ marginRight: 6 }}
              className='start-voting-text-icon'
            />
            <span>Start Voting</span>
          </Button>
        </div>
      )}
    </div>
  );
}

Motion.displayName = 'Motion on the Floor'