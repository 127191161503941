import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorMessage: null
};

const error = createSlice({
  name: 'error',
  initialState,
  reducers: {
    displayError: (state, action) => {
      state.errorMessage = action.payload.message;
    }
  }
});

export const {
  displayError
} = error.actions;

export default error.reducer;