import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import axios from 'axios';
import { VotecastAudience } from '../../constants/cloudVotingConstants';

export const LIMAWEB_JWT_TOKEN_URL = '/services/minutes/limaweb/api/votecastweb/auth/token';
export const LEGISLATE_JWT_TOKEN_URL = '/legislate/api/votecastweb/auth/token';
export const VCD_JWT_TOKEN_URL = '/vcd/api/votecastweb/auth/token';

export const getJWTToken = async (customer_uid , audience) => {
    try {
        let apiUrl;
        if (audience === VotecastAudience.LIMA_WEB) {
            apiUrl = LIMAWEB_JWT_TOKEN_URL;
        } else if (audience === VotecastAudience.LEGISLATE) {
            apiUrl = LEGISLATE_JWT_TOKEN_URL;
        } else if (audience === VotecastAudience.VCD) {
            apiUrl = VCD_JWT_TOKEN_URL;
        } else {
            console.error('Unknown audience: ',audience);
        }
        const response = await axios.post(apiUrl, {
            votecastweb_customer_uid: customer_uid
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching JWT token:', error);
    }
};

export const invokeGraphqlOperationWithNextToken = async (query, variables, authParameter) => {
    let allData = [];
    let nextToken = null;
    const authToken = await getJWTToken(authParameter?.customerId, authParameter.audience);
    do {
        const response = await GraphQLAPI.graphql(graphqlOperation(query, { ...variables, nextToken }, authToken));
        const key = Object.keys(response.data)[0];
        const data = response?.data?.[key];
        allData = [...allData, ...data.items];
        nextToken = data.nextToken;
    } while(nextToken);

    return allData;
}

export const invokeGraphqlOperation = async (query, variables, authParameter) => {
    const authToken = await getJWTToken(authParameter?.customerId, authParameter.audience);
    const response = await GraphQLAPI.graphql(graphqlOperation(query, variables, authToken));
    const key = Object.keys(response.data)[0];
    const data = response?.data?.[key];
    return data;
}